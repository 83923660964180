import React, {useEffect} from "react";
import Header from "../../components/header/Header";
import "./styles.css";
import {digitalPakistan, getPageTitle} from '../../Redux/variables'
import Lottie from "react-lottie";
import searchAnimation from "../../assets/animations/FAQs/animations/animation.json";
import online from "../../assets/animations/Services/online.json";
import property from "../../assets/animations/Services/Property.json";
import feasibility from "../../assets/animations/Services/feasibility.json";
import forensic from "../../assets/animations/Services/forensic.json";
import technical from "../../assets/animations/Services/technical.json";
import data from "../../assets/animations/Services/data.json";
import thankYou from "../../assets/animations/Thankyou/Thankyouaniamtion.json";
import ChatBox from "../../components/chatBox/ChatBox";
import { useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'
const DataServiceFAQs = (props) => {
  const history = useHistory();
  useEffect(()=>{
    GoogleView('Data Service FAQs',`/DataServiceFAQs`)
  },[])
  const search = {
    loop: true,
    autoplay: true,
    animationData: searchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: online,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: thankYou,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: property,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: feasibility,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions5 = {
    loop: true,
    autoplay: true,
    animationData: forensic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions6 = {
    loop: true,
    autoplay: true,
    animationData: technical,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions7 = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
      <>
        <Helmet>
          <title>Data as a Service {getPageTitle()}</title><meta name="description" content="" />
          {/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home | PropSure" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://www.propsure.com.pk/" />
          <meta property="og:site_name" content="PropSure" />
          <meta property="article:publisher" content="" />
          <meta property="og:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header state={props} />
        <ChatBox />
        {/* FREQUENTLY ASKED QUESTIONS SECTION STSRTS HERE */}
        <section>
          <div className="container-fluid ist-section">
            <div className="grey-color-1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 real-estate">
                  <h1 className="main-head">
                    Frequently <br /> Asked
                    <span>
                  {" "}
                      <br /> Questions
                </span>
                  </h1>
                </div>
                <div
                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
                    style={{ padding: "0%" }}
                >
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                      }}
                  >
                    <Lottie options={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FREQUENTLY ASKED QUESTIONS SECTION ENDS HERE */}
        {/* SERVICES SECTION STSRTS HERE */}
        <section style={{padding:'0px'}} className="second-section FrequentlyAskedQuestions">
          <div className="grey-color-2">
            <div className="container services">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-xs-12 icon-box">
                  <div className="row">
                    {/* <div className="search-area text-center">
                  <input
                    type="search"
                    autoComplete="off"
                    spellCheck="false"
                    role="combobox"
                    placeholder="Search"
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div> */}
                    <div className="catagories-heading text-center">
                      <h4>Categories</h4>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/OnlinePropertyVerificationFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/opvs.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>{digitalPakistan()}</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/PropertyInspectionFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/pins.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Property Inspection Services (PINS)</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/FeasibilityStudyFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/feasibility.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Feasibility Studies</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/ForensicAuditFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/forensic-audit.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Forensic Audit</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/TechnicalAdvisoryFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/technical.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Technical Advisory</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one">
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/w/data.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Data As A Service</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-xs-12">
                  <div className="main-two">
                    <h1>
                      Data as a<span> Service</span>
                    </h1>
                    <h5 id="WILDRE">
                      What is the significance of land data in real estate?
                    </h5>
                    <p>
                      Land and Real Estate assets constitute between 45% to 75% of
                      the total wealth of developing nations. While almost a third
                      of the global real estate industry, worth $11 trillion, is
                      managed on spreadsheets, the real estate sector of Pakistan
                      lags behind in utilizing information technology (IT),
                      especially in terms of maintaining and updating a database.
                    </p>
                    <div>
                      <hr className="main-hr" style={{ width: "90%" }} />
                    </div>
                    <h5 id="WDSPP">
                      What are the data services provided by PropSure?
                    </h5>
                    <p>
                      PropSure Digital Solutions is the first institution in
                      Pakistan that has managed to create the biggest spatial
                      database for the real estate industry. Its database has more
                      than 4 million verified properties across Pakistan with more
                      than 50 attributes and is continuously expanding on a daily
                      basis. The vector-based digital spatial data of PropSure
                      contains attributes of each plot/property situated in the
                      planned or approved housing societies across all major urban
                      centres of Pakistan.
                    </p>
                    <p>
                      Survey teams deputed in six offices of PropSure across
                      Pakistan have physically inspected each property included in
                      its database to verify their locations, attributes, and status
                      of the availability of amenities and utilities etc.
                      Furthermore, the status of the properties is periodically
                      rechecked and updated in the database.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WBSDB">
                      What are the benefits of having access to PropSure spatial
                      data bank?
                    </h5>
                    <p>
                      The access to the spatial data bank of PropSure by the
                      stakeholders of the Real Estate Industry and service providing
                      companies is an excellent value addition avenue available to
                      them. It can improve their operations along with enhancing
                      their clientele, market share, quality of service delivery,
                      efficiency, and ultimately the revenues.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WPUSDB">
                      Who are the potential users of PropSure spatial data bank?
                    </h5>
                    <p>
                      The potential users of spatial data bank can be:
                      <ol type="i">
                        <li>Telecommunication</li>
                        <li>
                          Line services managing companies (Water, Gas, Fiber
                          optics, internet, and TV cable)
                        </li>
                        <li>Address based services</li>
                        <li>Delivery of goods and products purchased Online</li>
                        <li>Postal services</li>
                        <li>Delivery services (food, taxi etc.)</li>
                        <li>Bank Financing & Insurance</li>
                        <li>Online Land Registry</li>
                        <li>Political parties for election campaign</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SERVICES SECTION ENDS HERE */}
        {/* FOOTER SECTION STSRTS HERE */}
        <ScrollButton />
        <Footer />
        {/* <section className="FrequentlyAskedQuestions-footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                className="img-fluid w-25"
                src={require("../../assets/img/Logo 2.png").default}
                alt="Logo"
              />
              <div className="para">
                <p>
                  PropSure Digital Solutions (Pvt) Ltd 4th Floor, Beverly
                  Centre, Blue Area, Islamabad
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="contact">
                <p>
                  Contact us at{" "}
                  <b style={{ fontWeight: "bold" }}> info@propsure.com.pk</b>{" "}
                  <br />
                  to get in touch with us.
                </p>
                <p>
                  <i className="fa fa-phone" style={{ fontSize: 20 }} />{" "}
                  051-8442244 <br />
                  021-34541403 <br />
                  042-36407379
                </p>
              </div>
              <div className="contact-icons">
                <i
                  className="fa fa-twitter"
                  aria-hidden="true"
                  style={{ paddingRight: "4%" }}
                />
                <i className="fa fa-instagram" style={{ paddingRight: "4%" }} />
                <i className="fa fa-facebook" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </>
  );
};

export default DataServiceFAQs;
