import {Helmet} from "react-helmet";
import {getPageTitle} from "../../Redux/variables";
import Header from "../../components/header/Header";
import React from "react";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/footer/Footer";

const Subscriptions = (props) => {

    return (
        <>
            <Helmet>
                <title>Subscriptions {getPageTitle()}</title>
                <meta name="description" content="" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Subscriptions | PropSure" />
                <meta property="og:description" content="" />
                <meta property="og:site_name" content="PropSure" />
                <meta property="article:publisher" content="" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header state={props} />

            <div className="bg-red-color">
                <section className="second-section bg-red-line1" id="Services-section">
                    <div className="">
                        <div className="container services">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 leftSide">
                                    <Sidebar {...props} />
                                </div>
                                <div className="col-lg-9 col-md-9 rightSide">
                                    <div className="card">
                                        <div className="card-header">Subscriptions</div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Month Package</h5>
                                                        </div>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">10 request</li>
                                                            <li className="list-group-item">Price Rs.3000</li>
                                                        </ul>
                                                        <div className="card-body">
                                                            <a href="#" className="btn btn-primary card-link">Buy</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Yearly Package</h5>
                                                        </div>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">1000 request</li>
                                                            <li className="list-group-item">Price Rs.50000</li>
                                                        </ul>
                                                        <div className="card-body">
                                                            <a href="#" className="btn btn-primary card-link">Buy</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Corporate Package</h5>
                                                        </div>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">10000 request</li>
                                                            <li className="list-group-item">Price Rs.100000</li>
                                                        </ul>
                                                        <div className="card-body">
                                                            <a href="#" className="btn btn-primary card-link">Buy</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}
export default Subscriptions;
