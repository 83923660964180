import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { db } from "./../../firebase/firebase";
import { GoogleLogin } from 'react-google-login';
import ReCAPTCHA from "react-google-recaptcha";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import {Link, Redirect, useHistory, useLocation} from "react-router-dom";

import { isMobile } from "../../utils";
import { Dropdown } from "react-bootstrap";
import { async } from "@firebase/util";
import { isMobileValid, userLoginData } from "../../Helper";
import axios from "axios";
import { laravelBaseUrl } from "../../config";
import {useDispatch, useSelector} from "react-redux";
import Auth from "../../Redux/Actions/Auth";
import {toast} from "react-toastify";
import ApiAuth from "../../API/ApiAuth";
import {GOOGLE_AUTH_CLIENT_ID, GOOGLE_CAPTCHA_SITE_KEY, removeSpacesFromString} from "../../Redux/variables";
import {GoogleEvent} from "../../Helper/GoogleView";
import TermAndCondition from "../TermAndCondition";

// const fProvider = new FacebookAuthProvider();
const gProvider = new GoogleAuthProvider();

const Header = ({state}) => {
  const {
    currentUser,
    setCurrentUser,
    loggedIn,
    setLoggedIn,
    mapClicked,
    setMapClicked,
  } = useContext(AuthContext);

  let location = useLocation();
  const {pathname}  = location

  const dispatch  = useDispatch()

  const history = useHistory();

  const [fullName, setFullName] = useState("");
  const [signInVisible, setSignInVisible] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const [clickTAC,setClickTAC] = useState(true)
  const scrollBottomHandle = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom){
      setRegisterChecked(true)
    } else{
      setRegisterChecked(false)
    }
  }

  const scrollTerm = useRef(null)
  const [signinEmail, setSigninEmail] = useState("");
  const [signinPassword, setSigninPassword] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupFirstName, setSignupFirstName] = useState("");
  const [signupLastName, setSignupLastName] = useState("");
  const [signupContact, setSignupContact] = useState("");
  // const [signupCNIC, setSignupCNIC] = useState("");
  // const [signupBirthMonth, setSignupBirthMonth] = useState("");
  // const [signupBirthDay, setSignupBirthDay] = useState("");
  // const [signupBirthYear, setSignupBirthYear] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [userInfoMissing, setUserInfoMissing] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [registerChecked, setRegisterChecked] = useState(false);
  const setRegisterCheckedHandle = (e) => {
    setRegisterChecked(!registerChecked);
  };
  const registerRef = useRef(null);
  const loginRef = useRef(null);

  const auth = getAuth();

  const userData = useSelector((state => state.user))

  useEffect(()=>{
    let userLogin = (Object.keys(userData.user)).length> 0?userData.user:{}
    setFullName(`${userLogin.name}`);
  },[userData.user])

  const [googleCaptcha,setGoogleCaptcha]  =useState('')
  const onGoogleChange = (value) => {
    setGoogleCaptcha(value)
  }

  const handleSignin = async (e) => {
    e.preventDefault();
    if (!googleCaptcha){
      toast.warning('Google Captcha is required.')
      return;
    }
    loginRef.current.innerHTML="Please wait ..."
    loginRef.current.disabled=true
    dispatch(Auth.loader(true))
    GoogleEvent({action:'AttemptLogin',params:1})
    await ApiAuth.login({
      email:signinEmail,
      password:signinPassword,
      googleCaptcha:googleCaptcha
    }).then((response)=> {

      const {success,message,data} = response
      if (success === true) {
        const {token, user} = data
        dispatch(Auth.loader(false))
        dispatch(Auth.login({token,user}))
        GoogleEvent({action:'LoginSuccess',params:1})
        GoogleEvent({action:`USER_${removeSpacesFromString(user.name).toUpperCase()}`,params:user.email})
        toast.success('Login Successfully')
        setSignInVisible(false);
      } else {
        toast.error(message)
        GoogleEvent({action:'LoginError',params:message})
      }
      loginRef.current.innerHTML="Login"
      loginRef.current.disabled=false
    }).catch((error)=>{
      toast.error(error)
      loginRef.current.innerHTML="Login"
      loginRef.current.disabled=false
      dispatch(Auth.loader(false))
      GoogleEvent({action:'LoginApiError',params:error})
    })



    return;
    axios({
      method: "post",
      url: `${laravelBaseUrl}login`,
      data: {
        email: signinEmail,
        password: signinPassword,
      },
    })
        .then(function (response) {
          if (response.data.success) {
            // setCurrentUser(response.data);
            setSignInVisible(false);
            setErrorMessage("");
          } else {
            setErrorMessage("Credentials does not match");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  };

  // const handleSignin = (event) => {
  //   // alert("Email: " + email + "\nPass: " + password);
  //   event.preventDefault();
  //   signInWithEmailAndPassword(auth, signinEmail, signinPassword)
  //     .then((userCredential) => {
  //       // Signed in
  //       const user = userCredential.user;
  //       console.log("logged in user: ", user);
  //       setErrorMessage(null);
  //       setSigninEmail("");
  //       setSigninPassword("");
  //       setSignInVisible(false);
  //       // ...
  //     })
  //     .then(() => {
  //       // resetInput();
  //     })
  //     .catch((error) => {
  //       console.log("Error: ", error);
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       if (errorCode === "invalid-email") {
  //         setErrorMessage("Invalid Email!");
  //       } else if (errorCode === "auth/user-disabled") {
  //         setErrorMessage("User is disabled!");
  //       } else if (errorCode === "auth/user-not-found") {
  //         setErrorMessage("User not found!");
  //       } else if (errorCode === "auth/wrong-password") {
  //         setErrorMessage("Wrong Password!");
  //       }
  //     });
  // };

  // const facebookSignin = () => {
  //   fProvider.addScope("email");

  //   signInWithPopup(auth, fProvider)
  //     .then((result) => {
  //       // The signed-in user info.
  //       const user = result.user;
  //       console.log("Facebook user: ", user);
  //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //       const credential = FacebookAuthProvider.credentialFromResult(result);
  //       const accessToken = credential.accessToken;

  //       // ...
  //     })
  //     .catch((error) => {
  //       console.log("Facebook signin error: ", error);
  //       // Handle Errors here.
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       // The email of the user's account used.
  //       const email = error.email;
  //       // The AuthCredential type that was used.
  //       const credential = FacebookAuthProvider.credentialFromError(error);

  //       // ...
  //     });
  // };

  const googleSignin = (e) => {
    e.preventDefault()
    gProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
    signInWithPopup(auth, gProvider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          const {providerData} = user
          const {displayName,email,phoneNumber,photoURL}  = providerData[0]
          console.log(displayName,email,phoneNumber,photoURL)
          console.log("Google User: ", user,credential);
          return;
          setSignInVisible(false);

          // ...
        })
        .catch((error) => {
          console.log("Google signin error: ", error);
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
  };

  const handleChangeContact = (e) => {
    setMobileError("");
    setSignupContact(e.target.value);
    if (!isMobileValid(e.target.value)) {
      setMobileError("Mobile Number is not valid.");
    }
  };

  // const handleChangeCNIC = (e) => {
  //   const re = /^[0-9\b]+$/;
  //   if (e.target.value === "" || re.test(e.target.value)) {
  //     setSignupCNIC(e.target.value);
  //   }
  // };

  // const handleChangeBirthMonth = (e) => {
  //   if (e.target.value === "Month") {
  //     return;
  //   }
  //   setSignupBirthMonth(e.target.value);
  // };
  // const handleChangeBirthDay = (e) => {
  //   if (e.target.value === "Day") {
  //     return;
  //   }
  //   setSignupBirthDay(e.target.value);
  // };
  // const handleChangeBirthYear = (e) => {
  //   if (e.target.value === "Year") {
  //     return;
  //   }
  //   setSignupBirthYear(e.target.value);
  // };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!googleCaptcha){
      toast.warning('Google Captcha is required.')
      return;
    }
    registerRef.current.innerHTML = "Please wait ...";
    registerRef.current.disabled = true;
    setMobileError("");
    if (!isMobileValid(signupContact)) {
      toast.error("Mobile Number is not valid.");
      registerRef.current.innerHTML = "Register";
      registerRef.current.disabled = false;
      return;
    }
    if (!registerChecked) {
      registerRef.current.innerHTML = "Register";
      registerRef.current.disabled = false;
      return;
    }
    dispatch(Auth.loader(true))
    await ApiAuth.register({
      f_name: signupFirstName,
      l_name: signupLastName,
      email: signupEmail,
      contact_no: signupContact,
      password: signupPassword,
      googleCaptcha:googleCaptcha,
    }).then((response)=> {
      if (response.success === true){
        toast.success(response.message)
        setSignupEmail('')
        setSignupFirstName('')
        setSignupLastName('')
        setSignupContact('')
        setSignUpVisible(false);
        registerRef.current.innerHTML="Register"
        registerRef.current.disabled = false;
      } else {
        const {message} = response
        const keys = Object.keys(message);
        let messages = ''
        keys.map((row,index) => {
          messages += (message[row][0] !== null && message[row][0] !== '' &&  message[row][0] !== undefined)?`${row} : ${message[row][0]} .`:''
        })
        toast.error(messages)
        registerRef.current.innerHTML="Register"
        registerRef.current.disabled = false;
      }
      registerRef.current.innerHTML="Register"
      registerRef.current.disabled = false;
    }).catch((error)=>{
      console.log(error,'Error')
      toast.error(error)
      registerRef.current.innerHTML="Register"
      registerRef.current.disabled = false;
      dispatch(Auth.loader(false))
    })

    return ;

    axios({
      method: "post",
      url: `${laravelBaseUrl}register`,
      data: {
        f_name: signupFirstName,
        l_name: signupLastName,
        email: signupEmail,
        contact_no: signupContact,
        password: signupPassword,
      },
    })
        .then(function (response) {
          if (response.data.success) {
            setCurrentUser(response.data);
            setSignUpVisible(false);
            registerRef.current.innerHTML = "Register";
            setErrorMessage("");
          } else {
            if (response.data.data.email) {
              setErrorMessage(response.data.data.email[0]);
            } else {
              setErrorMessage(response.data.message);
            }
            registerRef.current.innerHTML = "Register";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  };

  // const handleSignup = (event) => {
  //   event.preventDefault();
  //   registerRef.current.innerHTML = "Please wait ...";
  //   setMobileError("");
  //   if (!isMobileValid(signupContact)) {
  //     setMobileError("Mobile Number is not valid.");
  //     registerRef.current.innerHTML = "Register";
  //     return;
  //   }
  //   if (!registerChecked) {
  //     registerRef.current.innerHTML = "Register";
  //     return;
  //   }
  //   createUserWithEmailAndPassword(auth, signupEmail, signupPassword)
  //     .then((userCredential) => {
  //       // Signed in
  //       const user = userCredential.user;
  //       console.log("New Created user: ", user.uid);
  //       const userDoc = doc(db, "users", user.uid);
  //       setDoc(userDoc, {
  //         userID: user.uid,
  //         firstName: signupFirstName,
  //         lastName: signupLastName,
  //         email: signupEmail,
  //         password: signupPassword,
  //         contact: signupContact,
  //       })
  //         .then(() => {
  //           // console.log("Document written with ID: ", docRef.id);
  //           setErrorMessage(null);
  //           setSignupEmail("");
  //           setSignupPassword("");
  //           setSignupFirstName("");
  //           setSignupLastName("");
  //           setSignupContact("");
  //           // setSignupCNIC("");
  //           setSignUpVisible(false);

  //           registerRef.current.innerHTML = "Register";
  //         })
  //         .catch((e) => {
  //           console.error("Error adding document: ", e);
  //           registerRef.current.innerHTML = "Register";
  //         });

  //       // ...
  //     })
  //     .catch((error) => {
  //       registerRef.current.innerHTML = "Register";
  //       const errorCode = error.code;
  //       const errorMessage = error.message;

  //       console.log(
  //         "Error while creating new user: ",
  //         errorCode,
  //         " ,",
  //         errorMessage
  //       );
  //       if (errorCode === "auth/email-already-in-use") {
  //         setErrorMessage("Email already registered!");
  //       } else if (errorCode === "auth/invalid-email") {
  //         setErrorMessage("Email is not valid!");
  //       } else if (errorCode === "auth/weak-password") {
  //         setErrorMessage("Password is weak!");
  //       }
  //     });
  // };

  const responseGoogleError = (google) =>{
    console.log(google,'google')
    setGoogleLoader(false)
  }
  const [googleLoader,setGoogleLoader] = useState(false)

  const responseGoogle = (google) => {
    const {profileObj} = google
    const {email,name} = profileObj
    console.log(google,profileObj,email,name,'google')
    setGoogleLoader(true)
    ApiAuth.loginWithGoogleAccount({
      email,name
    }).then((response) => {
      const {success,message,data} = response
      console.log(success,message,data,response)
      if (success === true) {
        const {token, user} = data
        dispatch(Auth.loader(false))
        dispatch(Auth.login({token,user}))
        toast.success('Login Successfully')
        setSignInVisible(false);
      } else {
        toast.error(message)
      }
      setGoogleLoader(false)
    }).catch((error)=>{
      toast.error(error)
      setGoogleLoader(false)
    })
  }
  const signout = () => {
    ApiAuth.logout({}).then((response)=>{
      dispatch(Auth.logout({}))
      toast.success(response.message)
      state.history.push('/')
      setCurrentUser(null);
    }).catch((error)=>{
      dispatch(Auth.logout({}))
      state.history.push('/')
      toast.error('Unauthorized Access')
    })
    return;
    signOut(auth)
        .then(() => {
          setCurrentUser(null);
          setOpen(false);
          window.location.href = "/";
        })
        .catch((error) => {
          // An error happened.
        });
  };

  const getUserInfo = async () => {
    const docRef = doc(db, "users", currentUser.uid);
    const userData = await getDoc(docRef);

    if (userData.exists()) {
      console.log("Document data:", userData.data());
      const { firstName, lastName, contact } = userData.data();
      if (!firstName || !lastName || !contact) {
        console.log("User information incomplete...");
        setUserInfoMissing(true);
      } else {
        setFullName(`${firstName} ${lastName}`);
        console.log("User information is complete");
        setUserInfoMissing(false);
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setUserInfoMissing(true);
    }
  };

  const saveUserInfo = async (event) => {
    event.preventDefault();
    const userDoc = doc(db, "users", currentUser.uid);
    // Set the "capital" field of the city 'DC'
    setDoc(userDoc, {
      email: currentUser.email,
      userID: currentUser.uid,
      firstName: signupFirstName,
      lastName: signupLastName,
      contact: signupContact,
    })
        .then(() => {
          console.log("User information updated");
          setUserInfoMissing(false);
        })
        .catch((e) => {
          console.log("Error saving user info", e);
        });
  };

  useEffect(() => {
    if (state.location.state) {
      if (state.location.state.redirectTo) {
        console.log("MAP-REDIRECT", state.location.state.redirectTo);
        setMapClicked(true);
      }
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (mapClicked === true && !currentUser) setSignInVisible(true);
    return () => {};
  }, [mapClicked]);

  useEffect(() => {
    if (currentUser) {

      // getUserInfo();
    } else console.log("Not logged in");
    return () => {};
  }, [currentUser]);

  useEffect(() => {
    setErrorMessage(null);
  }, [signInVisible, signUpVisible]);

  const [forgetInVisible,setForgetInVisible] = useState(false)
  const [forgetEmail,setForgetEmail] = useState('')
  const forgetSubmit = useRef(null)
  const forgetSubmitSave = useRef(null)
  const [forgetSubmitInVisible,setForgetSubmitInVisible] = useState(false)
  const [forgetPassword,setForgetPassword] = useState('')
  const [forgetCode,setForgetCode] = useState('')
  const handleForgetSubmit = async (e) =>{
    e.preventDefault()
    forgetSubmit.current.innerHTML="Please wait ..."
    forgetSubmit.current.disabled=true
    dispatch(Auth.loader(true))
    await ApiAuth.forgetPassword({
      email:forgetEmail
    }).then((response1)=> {
      const {message,success} = response1.data
      if (success === true){
        toast.success(message)
        setForgetInVisible(false);
        setForgetSubmitInVisible(true);
      } else {
        console.log(message,'error')
        toast.error(message)
      }
      forgetSubmit.current.innerHTML="Forget Password"
      forgetSubmit.current.disabled=false
    }).catch((error)=>{
      toast.error(error)
      forgetSubmit.current.innerHTML="Forget Password"
      forgetSubmit.current.disabled=false
      dispatch(Auth.loader(false))
    })

  }
  const handleForgetSubmitPost = async (e) =>{
    e.preventDefault()
    forgetSubmitSave.current.innerHTML="Please wait ..."
    forgetSubmitSave.current.disabled=true
    dispatch(Auth.loader(true))
    await ApiAuth.forgetPasswordUpdate({
      email:forgetEmail,
      code:forgetCode,
      password:forgetPassword
    }).then((response)=> {
      console.log(response)
      const {message,success} = response
      if (success === true){
        forgetSubmitSave.current.innerHTML="Save"
        forgetSubmitSave.current.disabled=false
        toast.success(message)
        setForgetSubmitInVisible(false);
      } else {
        toast.error(message)
      }
    }).catch((error)=>{
      toast.error(error)
      forgetSubmitSave.current.innerHTML="Save"
      forgetSubmitSave.current.disabled=false
      dispatch(Auth.loader(false))
    })
  }

  return (
      <section className="nave-section">
        {mapClicked && currentUser && <Redirect to={"/map"} />}

        <nav
            className={`navbar ${
                isMobile() ? "navbar-fixed-top" : "navbar-expand-lg"
            }   navbar-light bg-transparent`}
            style={{
              position: "absolute",
              width: "100%",
              paddingRight: 10,
              right: 0,
              touchAction: "none",
              zIndex: 9,
            }}
        >
          <div className="container-fluid navbar-cont" style={{ padding: "0%" }}>
            <Link className="navbar-brand" to="/">
              {console.log(pathname,'pathname')}
              <img
                  className="img-fluid mt-2"
                  src={(pathname =='/events' || pathname === '/profile' || pathname === '/request-history' || pathname === '/subscriptions')?require("../../assets/img/PropSure-Logo-White-new.png").default:require("../../assets/img/PropSure-Logo-new.png").default}
                  alt=""
                  style={{ marginLeft: isMobile() ? "" : "80px",width: (pathname =='/events' || pathname !== '/profile' && pathname !== '/request-history' && pathname !== '/subscriptions')?'230px':'218px' }}
              />
              {/*{*/}
              {/*    (pathname !== '/profile' && pathname !== '/request-history' && pathname !== '/subscriptions') && (*/}
              {/*        <span style={{ fontWeight: "normal" }} className="fs-8 ms-2">*/}
              {/*          Prop*/}
              {/*          <span className="fw-bold fs-4">Sure</span>*/}
              {/*        </span>*/}
              {/*    )*/}
              {/*}*/}
            </Link>
            <button
                onClick={() => setOpen(!open)}
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
                aria-hidden={true}
                className={`${open ? "" : "collapse"} navbar-collapse`}
                id="navbarSupportedContent"
                style={{
                  paddingRight: 27,
                }}
            >
              <ul
                  style={{
                    position: isMobile() ? "fixed" : "inherit",
                    top: 0,
                    left: 0,
                    // marginLeft: "100px",
                    width: isMobile() ? "100%" : "",
                    height: isMobile() ? "100%" : "",
                    padding: isMobile() ? 100 : 0,
                    justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: isMobile() ? "#fff" : "transparent",
                  }}
                  className="nav navbar-nav"
              >
                {isMobile() && (
                    <li className="nav-item">
                      <button
                          onClick={() => {
                            setOpen(false);
                          }}
                          type="button"
                          class="btn-close text-reset"
                          // data-bs-dismiss="offcanvas"
                          aria-label="Close"
                      ></button>
                    </li>
                )}
                <li className="nav-item">
                  <a
                      onClick={() => {
                        history.push("/events");
                        setOpen(false);
                      }}
                      className="nav-link"
                      aria-current="page"
                      href="#"
                  >
                    <span className={'mws_badge'}>Events</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                      onClick={() => {
                        history.push("/");
                        setOpen(false);
                      }}
                      className="nav-link"
                      aria-current="page"
                      href="#WhyPropSure"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                      onClick={() => {
                        history.push("/");
                        setOpen(false);
                      }}
                      className="nav-link"
                      aria-current="page"
                      href="#Services-section"
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a
                      onClick={() => {
                        history.push("/");
                        setOpen(false);
                      }}
                      className="nav-link"
                      aria-current="page"
                      href="#Propmap-section"
                  >
                    PropMap
                  </a>
                </li>
                <li className="nav-item">
                  <a
                      onClick={() => {
                        history.push("/");
                        setOpen(false);
                      }}
                      className="nav-link"
                      aria-current="page"
                      href="#contact-us"
                  >
                    Contact Us
                  </a>
                </li>
                <Dropdown  align={{ lg: 'start' }} className={"dropdown-items"}>
                  <Dropdown.Toggle className={"fullName"} variant="">
                    {currentUser ? fullName : "Login"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {currentUser ? (
                        <>
                          <Dropdown.Item className={"nav-item"}>
                            <Link className={"nav-link"} to={"/profile"}>
                              {"Profile"}
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item className={"nav-item"}>
                            <Link className={"nav-link"} to={"/request-history"}>
                              {"Request History"}
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item className={"nav-item"}>
                            <a
                                onClick={signout}
                                className="nav-link"
                                aria-current="page"
                                href="/"
                                data-bs-toggle="modal"
                                data-bs-target="#SignIn"
                            >
                              Sign out
                            </a>
                          </Dropdown.Item>
                        </>
                    ) : (
                        <Dropdown.Item
                            onClick={() => {
                              setOpen(false);
                              setSignInVisible(!signInVisible);
                            }}
                        >
                          {" "}
                          <a
                              className="nav-link"
                              aria-current="page"
                              href="/"
                              data-bs-toggle="modal"
                              // data-bs-target="#SignIn"
                          >
                            Sign in
                          </a>
                        </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {currentUser ? (
                    <li hidden={true} className="nav-item" onClick={signout}>
                      <a
                          className="nav-link"
                          aria-current="page"
                          href="/"
                          data-bs-toggle="modal"
                          data-bs-target="#SignIn"
                      >
                        Sign out
                      </a>
                    </li>
                ) : (
                    <li
                        hidden={true}
                        className="nav-item"
                        onClick={() => {
                          setOpen(false);
                          setSignInVisible(!signInVisible);
                        }}
                    >
                      <a
                          className="nav-link"
                          aria-current="page"
                          href="/"
                          data-bs-toggle="modal"
                          // data-bs-target="#SignIn"
                      >
                        Sign in
                      </a>
                    </li>
                )}
              </ul>
            </div>
          </div>
        </nav>

        {/* Sign in modal */}
        <div
            className="modal"
            style={{
              display: signInVisible ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              // position: "-webkit-sticky" /* Safari */,
              position: "fixed",
              top: 0,
              bottom: 0,
              overflowY: "scroll",
              width: "100%",
              touchAction: "none",
              backgroundColor: "#00000070",
              zIndex: 10,
            }}
            // id="SignIn"
            tabIndex={100}
            aria-labelledby="SignIn"
            aria-hidden="false"
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div
                className="modal-content"
                style={{ margin: "auto", width: isMobile() ? "100%" : "500px" }}
            >
              <div className="modal-body">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 5,
                    }}
                >
                  <FontAwesomeIcon
                      onClick={() => {
                        setSignInVisible(!signInVisible);
                        setOpen(false);
                        setMapClicked(false);
                      }}
                      style={{
                        // margin: "auto",
                        cursor: "pointer",
                      }}
                      size="2x"
                      icon={faTimes}
                      color="#F35151"
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <form onSubmit={handleSignin}>
                      <div className="mb-3">
                        <input
                            value={signinEmail}
                            onChange={(e) => {
                              setSigninEmail(e.target.value);
                            }}
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            id="email"
                            required
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={signinPassword}
                            onChange={(e) => {
                              setSigninPassword(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            id="phnumber"
                            required
                        />
                      </div>
                      <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember-check"
                            // required
                        />
                        <label
                            className="form-check-label"
                            htmlFor="remember-check"
                            style={{ padding: "0%" }}
                        >
                          Remember me
                        </label>
                        <a
                            href="javascript:void(0)"
                            onClick={(e) => {
                              setOpen(false);
                              setSignInVisible(!signInVisible);
                              setForgetInVisible(!forgetInVisible);
                            }}
                            style={{
                              textDecoration: "none",
                              float: "right",
                              color: "#F35151",
                            }}
                        >
                          Forget Password?
                        </a>
                      </div>
                      {errorMessage && (
                          <text style={{ color: "red", alignSelf: "center" }}>
                            {errorMessage}
                          </text>
                      )}
                      <div className="mt-3 d-grid">
                        <div className="captcha-wrapper1">
                          <ReCAPTCHA
                              size={'normal'}
                              sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                              onChange={onGoogleChange}
                          />
                        </div>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                            className="btn btn-danger waves-effect waves-light"
                            type="submit"
                            ref={loginRef}
                            style={{
                              background:
                                  "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                                  //"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                            }}
                        >
                          Login
                        </button>
                      </div>
                      <div
                          className="mt-3 d-grid"
                          style={{ textAlign: "center" }}
                      >
                        {/* <p style={{ marginBottom: 0 }}>or</p> */}
                      </div>
                      {/* <div className="mt-3 d-grid">
                      <button
                        onClick={() => facebookSignin()}
                        className="btn btn-primary active waves-effect waves-light"
                        // type="submit"
                      >
                        Continue with Facebook
                      </button>
                    </div> */}
                      <div className="mt-3 d-grid">
                        <button hidden={true}
                                className="btn btn-light waves-effect waves-light"
                                type="button"
                                onClick={(e) => googleSignin(e)}
                        >
                          Continue with Google
                        </button>

                        <GoogleLogin
                            clientId={GOOGLE_AUTH_CLIENT_ID}
                            render={renderProps => (
                                <button className={'btn btn-light waves-effect waves-light'} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                  <img style={{paddingRight:'15px',width:'40px'}} src={require("../../assets/img/google-icon.svg").default} alt=""/>
                                  {
                                    !googleLoader ? 'Continue with Google':'Please wait ...'
                                  }
                                </button>
                            )}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogleError}
                            cookiePolicy={'single_host_origin'}
                        />
                      </div>
                      <div className="mt-3 d-grid text-center">
                        <p style={{ marginBottom: 0 }}>Don't have an account?</p>
                        <a
                            onClick={() => {
                              setOpen(false);
                              setSignInVisible(!signInVisible);
                              setSignUpVisible(!signUpVisible);
                            }}
                            href="/"
                            aria-current="page"
                            data-bs-toggle="modal"
                            data-bs-target="#SignInWithGmail"
                            style={{
                              textDecoration: "none",
                              color: "#F35151",
                              cursor: "pointer",
                            }}
                        >
                          Create a PropSure Account
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Register modal */}
        <div
            style={{
              display: signUpVisible ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              // position: "-webkit-sticky" /* Safari */,
              position: "fixed",
              top: 0,
              bottom: 0,
              overflowY: "scroll",
              width: "100%",
              backgroundColor: "#00000070",
              touchAction: "none",
              zIndex: 10,
            }}
            // className="modal fade"
            // id="SignInWithGmail"
            // tabIndex={-1}
            // aria-labelledby="SignInWithGmail"
            // aria-hidden="true"
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div
                className="modal-content"
                style={{ margin: "auto", width: isMobile() ? "100%" : "500px" }}
            >
              <div className="modal-body">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 5,
                    }}
                >
                  <FontAwesomeIcon
                      onClick={() => {
                        setOpen(false);
                        setSignUpVisible(!signUpVisible);
                        setMapClicked(false);
                      }}
                      style={{
                        // margin: "auto",
                        cursor: "pointer",
                      }}
                      size="2x"
                      icon={faTimes}
                      color="#F35151"
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <form onSubmit={handleSignup}>
                      <div className="mb-3">
                        <input
                            value={signupFirstName}
                            pattern="[A-Za-zs ]{1,}"
                            type="text"
                            className="form-control"
                            placeholder="First name*"
                            id="fname"
                            onChange={(e) => {
                              setSignupFirstName(e.target.value);
                            }}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={signupLastName}
                            pattern="[A-Za-zs ]{1,}"
                            type="text"
                            className="form-control"
                            placeholder="Last name*"
                            id="lname"
                            onChange={(e) => {
                              setSignupLastName(e.target.value);
                            }}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={signupEmail}
                            type="email"
                            className="form-control"
                            placeholder="Email adress*"
                            id="email"
                            onChange={(e) => {
                              setSignupEmail(e.target.value);
                            }}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={signupPassword}
                            type="password"
                            className="form-control input-text block large "
                            placeholder="Password*"
                            id="password"
                            onChange={(e) => {
                              setSignupPassword(e.target.value);
                            }}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={signupContact}
                            type="text"
                            className="form-control"
                            placeholder="Contact*"
                            id="phnumber"
                            onChange={handleChangeContact}
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                        {mobileError && (
                            <div className="text-danger mt-1">{mobileError}</div>
                        )}
                      </div>

                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" />
                        <label
                            className="form-check-label"
                            htmlFor="remember-check"
                            style={{ padding: "0%" }}
                        >
                          I’d like to receive coupons, promotions, surveys, and
                          updates via email about PropSure and its partnering
                          agencies.
                        </label>
                      </div>
                      {errorMessage && (
                          <text style={{ color: "red", alignSelf: "center" }}>
                            {errorMessage}
                          </text>
                      )}
                      <div className="mt-3 d-grid" style={{ fontSize: "14px" }}>
                        {
                          clickTAC && (
                                <div onScroll={scrollBottomHandle} className="term-and-conditions">
                                  <TermAndCondition/>
                                </div>
                            )
                        }
                        <div ref={scrollTerm} className="scroll-term-condition"></div>
                        <p>
                          <div className="form-check mt-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={registerChecked}
                                id="remember-check"
                                onChange={(e) => {
                                  setRegisterCheckedHandle(e);
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="remember-check"
                                style={{ padding: "0%" }}
                            >
                              By signing up, I agree to PropSure’s{" "} <b style={{color:'#0d6efd'}}>Terms &amp; Conditions.</b>
                            </label>
                          </div>
                        </p>
                      </div>
                      <div className="mt-3 d-grid">
                        <div className="captcha-wrapper1">
                          <ReCAPTCHA
                              size={'normal'}
                              sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                              onChange={onGoogleChange}
                          />
                        </div>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                            ref={registerRef}
                            disabled={!registerChecked || mobileError.length > 0}
                            className="btn btn-danger waves-effect waves-light"
                            type="submit"
                            style={{
                              background:
                                  "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                                  // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                            }}
                        >
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* userInfo missing modal */}
        <div
            style={{
              display: userInfoMissing ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              // position: "-webkit-sticky" /* Safari */,
              position: "fixed",
              top: 0,
              bottom: 0,
              overflowY: "scroll",
              width: "100%",
              backgroundColor: "#00000070",
              touchAction: "none",
              zIndex: 10,
            }}
            // className="modal fade"
            // id="SignInWithGmail"
            // tabIndex={-1}
            // aria-labelledby="SignInWithGmail"
            // aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div
                className="modal-content"
                style={{ margin: "auto", width: isMobile() ? "100%" : "500px" }}
            >
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <form onSubmit={saveUserInfo}>
                      <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            alignSelf: "center",
                          }}
                      >
                        Please complete your information
                      </p>
                      <div className="mb-3">
                        <input
                            value={signupFirstName}
                            pattern="[A-Za-zs ]{1,}"
                            type="text"
                            className="form-control"
                            placeholder="First name*"
                            id="fname"
                            onChange={(e) => {
                              setSignupFirstName(e.target.value);
                            }}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={signupLastName}
                            pattern="[A-Za-zs ]{1,}"
                            type="text"
                            className="form-control"
                            placeholder="Last name*"
                            id="lname"
                            onChange={(e) => {
                              setSignupLastName(e.target.value);
                            }}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>

                      <div className="mb-3">
                        <input
                            value={signupContact}
                            type="text"
                            className="form-control"
                            placeholder="Contact*"
                            id="phnumber"
                            onChange={handleChangeContact}
                            required
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        />
                      </div>

                      {errorMessage && (
                          <text style={{ color: "red", alignSelf: "center" }}>
                            {errorMessage}
                          </text>
                      )}
                      <div className="mt-3 d-grid">
                        <button
                            className="btn btn-danger waves-effect waves-light"
                            type="submit"
                            style={{
                              background:
                                  "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                                  // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                            }}
                        >
                          Save Information
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            className="modal"
            style={{
              display: forgetInVisible ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              // position: "-webkit-sticky" /* Safari */,
              position: "fixed",
              top: 0,
              bottom: 0,
              overflowY: "scroll",
              width: "100%",
              touchAction: "none",
              backgroundColor: "#00000070",
              zIndex: 10,
            }}
            // id="SignIn"
            tabIndex={100}
            aria-labelledby="ForgetPasswrod"
            aria-hidden="false"
        >
          <div className="modal-dialog modal-xl">
            <div
                className="modal-content"
                style={{ margin: "auto", width: isMobile() ? "100%" : "500px" }}
            >
              <div className="modal-body">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 5,
                    }}
                >
                  <FontAwesomeIcon
                      onClick={() => {
                        setForgetInVisible(!forgetInVisible);
                        setOpen(false);
                        setMapClicked(false);
                      }}
                      style={{
                        // margin: "auto",
                        cursor: "pointer",
                      }}
                      size="2x"
                      icon={faTimes}
                      color="#F35151"
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <form onSubmit={handleForgetSubmit}>
                      <div className="mb-3">
                        <input
                            value={forgetEmail}
                            onChange={(e) => {
                              setForgetEmail(e.target.value);
                            }}
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            id="email"
                            required
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                            className="btn btn-danger waves-effect waves-light"
                            type="submit"
                            ref={forgetSubmit}
                            style={{
                              background:
                                  "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                                  // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                            }}
                        >
                          Reset Password
                        </button>
                      </div>
                      <div
                          className="mt-3 d-grid"
                          style={{ textAlign: "center" }}
                      >
                        {/* <p style={{ marginBottom: 0 }}>or</p> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            className="modal"
            style={{
              display: forgetSubmitInVisible ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              // position: "-webkit-sticky" /* Safari */,
              position: "fixed",
              top: 0,
              bottom: 0,
              overflowY: "scroll",
              width: "100%",
              touchAction: "none",
              backgroundColor: "#00000070",
              zIndex: 10,
            }}
            // id="SignIn"
            tabIndex={100}
            aria-labelledby="ForgetPasswordUpdate"
            aria-hidden="false"
        >
          <div className="modal-dialog modal-xl">
            <div
                className="modal-content"
                style={{ margin: "auto", width: isMobile() ? "100%" : "500px" }}
            >
              <div className="modal-body">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 5,
                    }}
                >
                  <FontAwesomeIcon
                      onClick={() => {
                        setForgetSubmitInVisible(!forgetSubmitInVisible);
                        setOpen(false);
                        setMapClicked(false);
                      }}
                      style={{
                        // margin: "auto",
                        cursor: "pointer",
                      }}
                      size="2x"
                      icon={faTimes}
                      color="#F35151"
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <form onSubmit={handleForgetSubmitPost}>
                      <div className="mb-3">
                        <input
                            value={forgetEmail}
                            onChange={(e) => {
                              setForgetEmail(e.target.value);
                            }}
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            required
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={forgetCode}
                            onChange={(e) => {
                              setForgetCode(e.target.value);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Code"
                            required
                        />
                      </div>
                      <div className="mb-3">
                        <input
                            value={forgetPassword}
                            onChange={(e) => {
                              setForgetPassword(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            required
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                            className="btn btn-danger waves-effect waves-light"
                            type="submit"
                            ref={forgetSubmitSave}
                            style={{
                              background:
                                  "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                                  // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                            }}
                        >
                          Save
                        </button>
                      </div>
                      <div
                          className="mt-3 d-grid"
                          style={{ textAlign: "center" }}
                      >
                        {/* <p style={{ marginBottom: 0 }}>or</p> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
  );
};

export default Header;
