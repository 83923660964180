import { geoserverbase, geoserverStore } from "../../../config";
import { WMSTileLayer } from "react-leaflet";
import {getUserToken} from "../../../Redux/variables";
import {WMSTileLayerWithHeader} from "../CustomLayer";
import React from "react";

function WMSLayers(props) {
  const layers = [
    { name: ["country", "province", "city"], zoomstart: 2, zoomend: 11 },
    { name: "housing_scheme", zoomstart: 12, zoomend: 14 },
    { name: "phase_sector", zoomstart: 15, zoomend: 16 },
    { name: "block_subsector", zoomstart: 16, zoomend: 16 },
    // {name:'plot',zoomstart:17,zoomend:23},
  ];
  const zoomError = {
    '17': '10',
    '18': '20',
    '19': '25',
    '20': '25',
    '21': '25',
    '22': '25',
    '23': '25',
    '24': '25',
  };
  return (
    <>
      {layers.map((obj, i) => (
        <>
          {props.mapzoom >= obj.zoomstart && props.mapzoom <= obj.zoomend ? (
            <WMSTileLayer
              key={i}
              url={geoserverbase}
              version="1.1.0"
              layers={geoserverStore + obj.name}
              transparent="true"
              opacity="0.7"
              format="image/png"
              zIndex={10}
              maxZoom={23}
            />
          ) : props.mapzoom >= 17 ? (
            <>
              {props.plotfilter === "all" && (
                <>
                  <WMSTileLayer
                    url={geoserverbase}
                    version="1.1.0"
                    layers={geoserverStore + "plot"}
                    transparent="true"
                    opacity="0.4"
                    format="image/png"
                    zIndex={10}
                    maxZoom={23}
                  />
                </>
              )}

              {props.plotfilter === "Commercial" && (
                <>
                  <WMSTileLayer
                    url={geoserverbase}
                    version="1.1.0"
                    layers={geoserverStore + "plot"}
                    transparent="true"
                    opacity="0.4"
                    format="image/png"
                    zIndex={10}
                    maxZoom={23}
                    cql_filter="land_use='Commercial'"
                  />
                </>
              )}

              {props.plotfilter === "Residential" && (
                // <>
                <WMSTileLayer
                  url={geoserverbase}
                  version="1.1.0"
                  layers={geoserverStore + "plot"}
                  transparent="true"
                  opacity="0.4"
                  format="image/png"
                  zIndex={10}
                  maxZoom={23}
                  cql_filter="land_use='Residential'"
                />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ))}
      {
        (props.mapzoom > 16) ? (
            <div className={'plot-errors-data alert alert-warning'}>
              The data provided is collected and processed from drone images, aerial photographs and/or
              open-source images and is provided for reference only. It is understood and agreed that
              there may be a difference of up
              to <b>{(zoomError[props.mapzoom] !== undefined ? zoomError[props.mapzoom] : '10')}%</b> in
              the placement and/or area in the data provided and
              the actual placement and/or area due to the sources from which the data is collected and
              processed. Users are therefore advised to contact the competent authority for confirmation
              of the actual placement and/or area.
            </div>
        ):''
      }
    </>
  );
}

export default WMSLayers;
