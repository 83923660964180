
const SubscriptionBar   =   (props) =>  {
    return (
        <>
            <div className="subscriptions d-none">
                <div className="btn-group" role="group">
                    <button type="button" className="btn btn-danger"><b>14</b> days trial remaining.</button>
                    <button type="button" className="btn btn-danger"><b>5</b> requests.</button>
                </div>
            </div>
        </>
    )
}

export default SubscriptionBar;
