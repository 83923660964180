import React from "react";
import "./styles.css";

import Lottie from "react-lottie";
import verification from "../../assets/animations/VerifiedAnimation.json";

const WhyPropMap = () => {
  const defaultAnimationOptions = {
    // background:
    //   "radial-gradient(48.68% 52.38% at 51.53% 48.33%, #FE6060 0%, #C62525 100%), radial-gradient(43.6% 45.97% at 50.07% 47.98%, #FD8181 0%, #ED4949 38.84%, #E32C2C 86.81%)",
    loop: true,
    autoplay: true,
    animationData: verification,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {/* WHY PROPSURE SECTION STSRTS HERE */}
      <section className="fourth-section" id="WhyPropSure">
        <div className="bg-red-line2">
          <div className="container Why-PropSure">
            <div className="row">
              <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{ padding: 0 }}
              >
                {/* <div>
                <lottie-player
                  src="./frameworks/Lottie Animation/VerifiedAnimation.json"
                  background="radial-gradient(48.68% 52.38% at 51.53% 48.33%, #FE6060 0%, #C62525 100%), radial-gradient(43.6% 45.97% at 50.07% 47.98%, #FD8181 0%, #ED4949 38.84%, #E32C2C 86.81%);"
                  speed={1}
                  style={{ margin: "0 auto" }}
                  loop
                  autoPlay
                />
              </div> */}
                <div
                    style={{
                      margin: "auto",
                      background:
                          "none",
                    }}
                >
                  <Lottie options={defaultAnimationOptions} />
                </div>
              </div>
              <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{ padding: 0 }}
              >
                <div className="whyprpsurs-content">
                  <div className="h1 text-light fw-bold">Why PropSure?</div>
                  <p>
                    PropSure Digital Solutions aims at transforming Pakistan’s
                    real estate industry through disruptive digital solutions and
                    technical expertise. Being a pioneer, we facilitate our valued
                    clients and partners to understand industry dynamics and make
                    informed decisions to grab opportunities regarding real estate
                    investments and developments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* WHY PROPMAP SECTION ENDS HERE */}
    </>
  );
};

export default WhyPropMap;
