import Header from "../../components/header/Header";
import React from "react";
import Footer from "../../components/footer/Footer";
import cover from '../../assets/events/cover.jpeg'
import locationDubai from '../../assets/events/locationDubai.png'
import calenderDubai from '../../assets/events/calenderDubai.png'
import './app.css';
import LeftSide from "./LeftSide";
import RightForm from "./RightForm";


const Events = (props) => {

    return (
        <>
            <Header state={props}/>
            <div className="bg-red-color">
                <section className="second-section bg-red-line1" id="Services-section">
                    <div className="services mt-5">
                        <div className="blog-page-wrap">
                            <div className="image-wrap">
                                <div className="granna-club-banner">
                                    <img src={cover}
                                         className="attachment-full size-full wp-post-image" alt="" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                        <div className="single-content-container">
                            <div className="single-area-content">
                                <div className="container container-dubai">
                                    <div className="form-content">
                                        <div className="row content-form">
                                            <LeftSide/>
                                            <RightForm/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </>
    );
}

export default Events;