import React, {useEffect} from "react";
import Header from "../../components/header/Header";
import "./styles.css";
import {digitalPakistan, getPageTitle} from '../../Redux/variables'

import Lottie from "react-lottie";
import searchAnimation from "../../assets/animations/FAQs/animations/animation.json";
import online from "../../assets/animations/Services/online.json";
import property from "../../assets/animations/Services/Property.json";
import feasibility from "../../assets/animations/Services/feasibility.json";
import forensic from "../../assets/animations/Services/forensic.json";
import technical from "../../assets/animations/Services/technical.json";
import data from "../../assets/animations/Services/data.json";
import thankYou from "../../assets/animations/Thankyou/Thankyouaniamtion.json";
import ChatBox from "../../components/chatBox/ChatBox";
import { useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'

const TechnicalAdvisoryFAQs = (props) => {
  const history = useHistory();
  useEffect(()=>{
    GoogleView('Technical Advisory FAQs',`/TechnicalAdvisoryFAQs`)
  },[])
  const search = {
    loop: true,
    autoplay: true,
    animationData: searchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: online,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: thankYou,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: property,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: feasibility,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions5 = {
    loop: true,
    autoplay: true,
    animationData: forensic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions6 = {
    loop: true,
    autoplay: true,
    animationData: technical,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions7 = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
      <>
        <Helmet>
          <title>Technical Advisory {getPageTitle()}</title><meta name="description" content="" />
          {/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home | PropSure" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://www.propsure.com.pk/" />
          <meta property="og:site_name" content="PropSure" />
          <meta property="article:publisher" content="" />
          <meta property="og:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header state={props} />
        <ChatBox />
        {/* FREQUENTLY ASKED QUESTIONS SECTION STSRTS HERE */}
        <section>
          <div className="container-fluid ist-section">
            <div className="grey-color-1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 real-estate">
                  <h1 className="main-head">
                    Frequently <br /> Asked
                    <span>
                  {" "}
                      <br /> Questions
                </span>
                  </h1>
                </div>
                <div
                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
                    style={{ padding: "0%" }}
                >
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                      }}
                  >
                    <Lottie options={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FREQUENTLY ASKED QUESTIONS SECTION ENDS HERE */}
        {/* SERVICES SECTION STSRTS HERE */}
        <section style={{padding:'0px'}} className="second-section FrequentlyAskedQuestions">
          <div className="grey-color-2">
            <div className="container services">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-xs-12 icon-box">
                  <div className="row">
                    {/* <div className="search-area text-center">
                  <input
                    type="search"
                    autoComplete="off"
                    spellCheck="false"
                    role="combobox"
                    placeholder="Search"
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div> */}
                    <div className="catagories-heading text-center">
                      <h4>Categories</h4>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/OnlinePropertyVerificationFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/opvs.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>{digitalPakistan()}</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/PropertyInspectionFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/pins.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Property Inspection Services (PINS)</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/FeasibilityStudyFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/feasibility.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Feasibility Studies</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/ForensicAuditFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/forensic-audit.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Forensic Audit</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one">
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/w/technical.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Technical Advisory</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/DataServiceFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/data-as-a-service.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Data As A Service</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-xs-12">
                  <div className="main-two">
                    <h1>
                      Technical <span>Advisory</span>
                    </h1>
                    <h5 id="WITAS">
                      What is the vitality of technical advisory services provided
                      by PropSure?
                    </h5>
                    <p>
                      The first step involved in the process of project making is to
                      conceive an idea. The very idea after going through the
                      process of evaluation and feasibility assessments reaches at
                      the stage of getting it implement on ground. After the site
                      selection and land acquisition, the task of project design,
                      documentation and approval from the competent authority is
                      undertaken. It is indeed a challenging task to design the
                      project, especially in compliance with the entire complex
                      planning parameters, by-laws, construction standards and
                      specifications, environmental, health and safety standards.
                      The project proposal can easily get rejected by the regulatory
                      authority if not designed, documented, and submitted properly.
                      This potentially leads to a greater chance of jeopardizing
                      investment. In that case, PropSure technical advisory services
                      are extremely vital in terms of providing technical
                      assistance, expert guidance and advice for the design and
                      execution of any project.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WKTASPO">
                      What kind of technical advisory services does PropSure offer?
                    </h5>
                    <p>
                      PropSure Digital Solutions, through its highly skilled,
                      experienced, and dedicated team of experts and professionals,
                      provides technical advisory services to resolve the design and
                      documentation issues for project approvals. These projects
                      could be of various types including housing societies, and
                      structures that may include high-rise multifunctional
                      buildings and hospitality buildings like hotels, hospitals,
                      and schools. PropSure has the capabilities to provide reliable
                      services and technical advice for project site selection, land
                      acquisition, design and documentation, compliance of
                      regulatory requirements, assistance for submission and
                      obtaining approvals from the regulatory authorities.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WSTASPP">
                      What are those projects for which PropSure has provided
                      technical advisory services?
                    </h5>
                    <p>
                      <ul>
                        <li>
                          <a
                              href="https://www.cda.gov.pk/buildingControl/buildingProjects"
                              target="_blank"
                          >
                            Mall of Arabia-Islamabad Highway
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://imarat.com.pk/project/amazon-mall/"
                              target="_blank"
                          >
                            Amazon Outlet Mall-GT Road Islamabad
                          </a>
                        </li>
                        <li>
                          {/* <a href="" target="_blank"> */}
                          Capital Icon-Islamabad Highway
                          {/* </a> */}
                        </li>
                        <li>
                          <a
                              href="https://www.cda.gov.pk/buildingControl/buildingProjects"
                              target="_blank"
                          >
                            Amazon Hotel G-11 Markaz Islamabad
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://www.cda.gov.pk/housing/society.asp?varId=1"
                              target="_blank"
                          >
                            Margalla View Housing Society-D-12 Islamabad
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://imarat.com.pk/project/imarat-builders-mall/"
                              target="_blank"
                          >
                            Imarat Builders Mall-GT Road Islamabad
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://imarat.com.pk/project/golf-floras/"
                              target="_blank"
                          >
                            Golf Floras-Bahria Garden City
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://www.cda.gov.pk/housing/society.asp?varId=47"
                              target="_blank"
                          >
                            Capital enclave Housing Society-Zone-V Islamabad
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://www.cda.gov.pk/buildingControl/buildingProjects"
                              target="_blank"
                          >
                            Mall of Arabia Extension-Islamabad Highway
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://www.google.com/search?rlz=1C1GCEA_enPK972PK972&q=pearl+tower+e11+islamabad&npsic=0&rflfq=1&rldoc=1&rllag=33697534,72974387,819&tbm=lcl&sa=X&ved=2ahUKEwiRzOHShuP0AhW5Q_EDHRa1DNYQtgN6BAgDEFo&biw=1536&bih=754&dpr=1.25#rlfi=hd:;si:11208601365499287134,l,ChlwZWFybCB0b3dlciBlMTEgaXNsYW1hYmFkWhsiGXBlYXJsIHRvd2VyIGUxMSBpc2xhbWFiYWSSARJhcGFydG1lbnRfYnVpbGRpbmeqARMQASoPIgtwZWFybCB0b3dlcigA;mv:[[33.7144993,73.205676],[33.486815299999996,72.9538706]]"
                              target="_blank"
                          >
                            Pearl Towers E-11 Islamabad
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://www.cda.gov.pk/housing/society.asp?varId=64"
                              target="_blank"
                          >
                            Al-Makkah Housing Scheme Zone 5 Islamabad
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://imarat.com.pk/project/imarat-residence/"
                              target="_blank"
                          >
                            Imarat Residences-Capital Expressway Islamabad
                          </a>
                        </li>
                        <li>
                          {/* <a href="" target="_blank"> */}
                          IBD-Islamabad Highway
                          {/* </a> */}
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SERVICES SECTION ENDS HERE */}
        {/* FOOTER SECTION STSRTS HERE */}

        <ScrollButton />
        <Footer />
        {/* <section className="FrequentlyAskedQuestions-footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                className="img-fluid w-25"
                src={require("../../assets/img/Logo 2.png").default}
                alt="Logo"
              />
              <div className="para">
                <p>
                  PropSure Digital Solutions (Pvt) Ltd 4th Floor, Beverly
                  Centre, Blue Area, Islamabad
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="contact" style={{ float: "right" }}>
                <p>
                  Contact us at{" "}
                  <b style={{ fontWeight: "bold" }}> info@propsure.com.pk</b>{" "}
                  <br />
                  to get in touch with us.
                </p>
                <p>
                  <i className="fa fa-phone" style={{ fontSize: 20 }} />{" "}
                  051-8442244 <br />
                  021-34541403 <br />
                  042-36407379
                </p>
              </div>
              <div className="contact-icons">
                <i
                  className="fa fa-twitter"
                  aria-hidden="true"
                  style={{ paddingRight: "4%" }}
                />
                <i className="fa fa-instagram" style={{ paddingRight: "4%" }} />
                <i className="fa fa-facebook" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </>
  );
};

export default TechnicalAdvisoryFAQs;
