import React from "react";
import { useContext, useState } from "react";
import "./styles.css";
import { AuthContext } from "../../context/AuthContext";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import WMSLayers from "../../pages/PropSureMap/components/WMSLayers";
import { mapbounds } from "../../config";

const PropMap = () => {
  const [mapzoom, setmapzoom] = useState(6);

  const { currentUser, setLoggedIn, setMapClicked } = useContext(AuthContext);

  const checkUser = () => {
    if (currentUser) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      setMapClicked(true);
    }
  };

  return (
    <>
      {/* PROPMAP SECTION STSRTS HERE */}
      <section className="propmap third-section" id="Propmap-section">
        <div className="bg-red-line1">
          <div className="container-fluid ">
            <div className="row">
              <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{ padding: 0 }}
              >
                <div className="propmap-content">
                  <div className="h1 text-light fw-bold">PropMap</div>
                  <p>
                    PropSure Digital Solutions is the only platform that has
                    managed to create the biggest spatial database for Pakistan’s
                    real estate sector and develop a state-of-the-art digital map
                    for the entire country.
                    <br />
                    <button
                        className="map-btn mt-4 fw-bold"
                        style={{
                          marginLeft: "-3px",
                          marginTop: "15px",
                          width: "220px",
                          height: "40px",
                          boxShadow: `3px 3px 5px rgba(255,255,255, 0.9)`,
                          // boxShadow: `3px 3px 5px #1363df`,
                        }}
                        onClick={() => checkUser()}
                    >
                      Find Your Digital Address
                    </button>
                  </p>
                </div>
              </div>
              <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12 "
                  style={{ padding: 0, zIndex: 8 }}
              >
                <MapContainer
                    center={[30.3753, 69.3451]}
                    minZoom={6}
                    maxZoom={18}
                    zoom={6}
                    // maxBounds={mapbounds}
                    whenCreated={(map) => {
                      map.on("zoomend", () => {
                        setmapzoom(map.getZoom());
                      });
                    }}
                >
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Street View">
                      <TileLayer
                          attribution="openstreetmaps"
                          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                          maxZoom="22"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Satellite View">
                      <TileLayer
                          attribution="googlemaps"
                          url="https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
                          maxZoom="18"
                      />
                    </LayersControl.BaseLayer>
                  </LayersControl>
                  <WMSLayers mapzoom={mapzoom} plotfilter={"all"} />
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* PROPMAP SECTION ENDS HERE */}
    </>
  );
};

export default PropMap;
