import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {Drawer} from "@mui/material";
import "./infoPanel.css";
import Swal from 'sweetalert2'

import swal from "sweetalert";

import {Button} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
} from "firebase/firestore";
import {isMobile} from "../../../../utils";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../../../context/AuthContext";
import ApiAuth from "../../../../API/ApiAuth";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {GOOGLE_CAPTCHA_SITE_KEY, locateYourProperty} from "../../../../Redux/variables";
import ReCAPTCHA from "react-google-recaptcha";

const userdata = [];

const firebasedata = async (params) => {
    const q = query(
        collection(getFirestore(), "users"),
        where("userID", "==", params)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        userdata.push(doc._document.data.value.mapValue.fields);
    });
};

const InfoPanel = (props) => {

    const userData = useSelector((state => state.user))
    const [buttonLoader, setButtonLoader] = useState(false)
    const {currentUser} = useContext(AuthContext);
    const [plotLoaded, setPlotLoaded] = useState(false)
    const [googleCaptcha, setGoogleCaptcha] = useState('')
    //popup state
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([
        {
            title: 'Basic Property (OPVS) Report',
            slug: 'opvs',
            active: false
        },
        {
            title: 'Regulatory Plot Report',
            slug: 'regulatory-plot',
            active: false
        },
        {
            title: 'Bylaws Report',
            slug: 'bylaws',
            active: false

        },
        {
            title: 'Master Plan Report',
            slug: 'master-plan',
            active: false

        },
        {
            title: 'Demarcation Report',
            slug: 'demarcation',
            active: false

        }
    ]);
    const getCurrentOption = (options, slug) => {
        let flag = -1;
        options.map((row, index) => {
            if (slug === row.slug) {
                flag = index
            }
        })
        return flag
    }
    const optionsChange = (e) => {
        const slug = e.target.value

        const optionKey = getCurrentOption(options, slug)
        if (optionKey !== -1) {
            options[optionKey].active = e.target.checked;
            setOptions((option) => ([...options]))
        }
        console.log(slug);
    }


    const onGoogleChange = (value) => {
        setGoogleCaptcha(value);
        console.log(value, 'VALUE Google')
    }
    useEffect(() => {
        setPlotLoaded(userData.loader)
    }, [userData.loader])
    useEffect(() => {
        if (!currentUser && Object.keys(currentUser) === 0) {
            props.history.push('/')
        }
    }, [])
    /* firebasedata(props.user.uid);*/

    const sendRequest = (event) => {
        if (!googleCaptcha) {
            swal({icon: "warning", text: 'Google Captcha is required'});
            //  return;
        }
        if (!currentUser && Object.keys(currentUser) === 0) {
            props.history.push('/')
        }

        var contact_number = currentUser.contact_no;
        const fullName = (currentUser.name).split(' ')
        let f_name = ''
        let l_name = ''
        if (fullName.length === 2) {
            f_name = fullName[0]
            l_name = fullName[1]
        } else if (fullName.length === 1) {
            f_name = fullName[0]
            l_name = fullName[0]
        } else if (fullName.length === 3) {
            f_name = fullName[0]
            l_name = `${fullName[1]} ${fullName[2]}`
        } else if (fullName.length === 4) {
            f_name = fullName[0]
            l_name = `${fullName[1]} ${fullName[2]} ${fullName[3]}`
        } else if (fullName.length === 5) {
            f_name = fullName[0]
            l_name = `${fullName[1]} ${fullName[2]} ${fullName[3]} ${fullName[4]}`
        }

        let user_id = currentUser.id,

            email = currentUser.email,
            housing_scheme = props.plotData.properties.housing_scheme_id,
            plot_id = Number(props.plotData.id.split(".")[1]),
            contact_no = contact_number.substring(1),
            city_id = props.plotData.properties.city_id;

        let data = {
            f_name,
            l_name,
            email,
            user_id,
            contact_no,
            reference_no: 'pvc',
            housing_scheme: housing_scheme,
            plot_id: plot_id,
            city_id: city_id,
            domain: 'propsure',
            googleCaptcha: googleCaptcha,
            captcha:true
        }


        setButtonLoader(true)


        const selectedOptions = getActionOption(options);
        console.log(selectedOptions)
        const dones = [];
        selectedOptions.map(async (row, index) => {
            console.log(row, index, dones)
            if (row.slug !== 'opvs') {
                await ApiAuth.sendPinsRequest(row.slug, data).then((response) => {
                    const {success, message, data} = response
                    dones.push({
                        page: row.slug,
                        message: message
                    })
                })
            } else {
                console.log(data);
                await ApiAuth.sendPlotRequest(data).then((response) => {
                    const {success, message, data} = response
                    if (success === true) {
                    }
                }).catch((error) => {
                    toast.error(error)
                })
            }
            setButtonLoader(false)

            let messages = '';
            dones.map((str, i) => {
                messages += `
                <!--<b>${str.page}</b> :-->
                    ${str.message} <br/>`;
            })
            setShow(false)
            Swal.fire({
                icon: "success",
                // html: true,
                html: `Your request has been initiated, Please proceed for payment.<br/> ${messages}`,
                // text: "Your request has been initiated, Please proceed for payment. " + messages + "",
            }).then((value) => {
                window.location.reload();
                /*  window.location.href = payment_url;*/
            })
        })

    }

    const getActionOption = (options) => {
        const rows = [];
        options.map((row, i) => {
            if (row.active === true) {
                rows.push(row)
            }
        })
        return rows;
    }

    const toggleDrawer = (anchor, open) => (event) => {
        console.log(anchor, open, 'open')
        props.setState({...props.state, [anchor]: open});
    };

    const list = (anchor) => (
        <>
            <Box
                sx={{width: anchor === "top" || anchor === "bottom" ? "auto" : 280}}
                role="presentation"
                onClick={toggleDrawer(anchor, true)}
                onKeyDown={toggleDrawer(anchor, true)}
            >
                {!isMobile() && (
                    <img src={require("../../../../assets/img/search-bg.png").default}/>
                )}
                {
                    (plotLoaded) ? (
                        <div className={'text-danger text-center mt-5 mb-2'}><b>Please wait...</b></div>
                    ) : (
                        (props.plotData) && (<>
                                <List className="border border-2 sm m-2 my-5 mt-2">
                                    <div className="fw-bold mx-2">Address:</div>
                                    <div className="m-2 text-danger">
                                        {/* plot */}
                                        {props.plotData &&
                                            "Plot/House # " + props.plotData.properties.plot_no}

                                        {/* street */}
                                        {props.plotData &&
                                            props.plotData.properties.street &&
                                            " Street " + props.plotData.properties.street}

                                        {/* phase sector */}
                                        {props.plotData &&
                                            props.plotData.properties.phase_sector_name &&
                                            " " + props.plotData.properties.phase_sector_name}
                                        {/* housing scheme */}
                                        {props.plotData &&
                                            props.plotData.properties.housing_scheme_name &&
                                            " " + props.plotData.properties.housing_scheme_name}

                                        {/* city name */}
                                        {props.plotData &&
                                            props.plotData.properties.city_name &&
                                            " " + props.plotData.properties.city_name}
                                    </div>
                                </List>
                            </>
                        )
                    )
                }
            </Box>
            {
                (!plotLoaded) && (
                    <Box className="d-flex justify-content-center">
                        {props.plotData &&
                            (props.plotData.properties.land_use == "Commercial" ||
                                props.plotData.properties.land_use == "Residential") && (
                                <div
                                    className="button-portion"
                                    style={{position: "relative", bottom: 26}}
                                >
                                    <Button type={'button'} className="request-btn" onClick={() => setShow(true)}>
                                        {buttonLoader ? 'Please wait ...' : locateYourProperty()}
                                    </Button>
                                    {/*Model*/}
                                    <Modal
                                        show={show}
                                        onHide={() => setShow(false)}
                                        dialogClassName="modal-90w"
                                        // aria-labelledby="example-custom-modal-styling-title"
                                    >
                                        <Modal.Header closeButton>
                                            <b><label><h4>PINS Report</h4></label></b>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {/*{JSON.stringify(options)}*/}
                                            <ul className="list-group">
                                                {options.map((item, i) => (
                                                    <li className="list-group-item" key={i}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox"
                                                                   checked={item.active}
                                                                   value={item.slug}
                                                                   onChange={(item) => {
                                                                       optionsChange(item)
                                                                   }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                // htmlFor="remember-check"
                                                                style={{padding: "0%"}}
                                                            >
                                                                <b>{item.title}</b>
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>

                                            <div className="captcha-wrapper p-2">
                                                <ReCAPTCHA
                                                    size={'compact'}
                                                    sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                                                    onChange={onGoogleChange}
                                                />
                                            </div>
                                            <button hidden={false}
                                                    disabled={buttonLoader}
                                                    type={'button'}
                                                    onClick={sendRequest}
                                                    className="request-btn "
                                            >{buttonLoader ? 'Please wait ...' : locateYourProperty()}</button>
                                        </Modal.Body>
                                    </Modal>
                                    {/*end Model*/}

                                </div>

                            )}
                    </Box>
                )
            }
        </>
    );

    return (
        <>

            <div style={{position: "absolute"}}>
                {["left", "right", "top", "bottom"].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={props.state[anchor]}
                            onClose={toggleDrawer(anchor, true)}
                            onOpen={toggleDrawer(anchor, true)}
                            variant="persistent"
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
}
export default InfoPanel
