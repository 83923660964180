import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from "react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import ApiAuth from "../../API/ApiAuth";
import {toast} from "react-toastify";

const ChatBox = () => {
  const [chatBoxVisible, setChatBoxVisible] = useState(false);
  const [name,setName] = useState('')
  const submitRef = useRef(null)
  const [message,setMessage] = useState('')
  const [valid,setValid] = useState(false)
  const changeHandle =(e,key) => {
    if (key === 'name') {
      setName(e.target.value)
    }
    if (key === 'message') {
      setMessage(e.target.value)
    }
  }
  const submit = () => {
    if (name !== '' && message !== '' ) {
      submitRef.current.innerHTML = 'Please wait...'
      setValid(true)
      ApiAuth.sendMessage({name,message}).then((response) =>{
        const {success,message} = response
        if (success === true ) {
          toast.success(message)
          setName('')
          setMessage('')
          setChatBoxVisible(false)
        } else {
          toast.error(message)
        }
        setValid(false)
        submitRef.current.innerHTML = 'Submit'
      }).catch((error)=>{
        setValid(false)
      })
    }
  }
  return (
    <>
      {/* Cheat-box Starts here */}
      <section className="chat-box" id="chat-box">
        <div className="container">
          <div className="row">
            <div className="chat-box">
              <div className="chat-head text-center">
                <img
                  onClick={() => setChatBoxVisible(!chatBoxVisible)}
                  className="btn"
                  src={require("../../assets/img/Chat-Box.png").default}
                  style={{ paddingLeft: "70%", height: 100, zIndex: 20 }}
                  alt=""
                />
              </div>
              <div
                style={{
                  display: chatBoxVisible ? "flex" : "none",
                  marginBottom: 5,
                }}
                className="chat-body"
              >
                <form action="input">
                  <a
                    href="/"
                    style={{
                      color: "#323244",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/logo_main.png").default}
                      style={{ width: 40, height: 45 }}
                      alt=""
                    />
                    HelpDesk
                  </a>
                  <FontAwesomeIcon
                    onClick={() => setChatBoxVisible(!chatBoxVisible)}
                    className="float-end"
                    size="x"
                    icon={faTimesCircle}
                    color="red"
                  />
                  <p style={{ paddingTop: 10 }}>Leave us a message.</p>
                  <label htmlFor="first-name">Name</label>
                  <input autoComplete={'off'} className="form-control" type="text" defaultValue={name} onChange={(e)=>{changeHandle(e,'name')}} id="first-name" />
                  <label htmlFor="messageBox">Message</label>
                  <textarea
                    id="messageBox"
                    className="message"
                    name="comments"
                    style={{ width: "100%" }}
                    defaultValue={message}
                    onChange={(e)=>{changeHandle(e,'message')}}
                  />

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-danger"
                      type="button"
                      disabled={valid}
                      ref={submitRef}
                      onClick={submit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cheat-box ends here */}
    </>
  );
};

export default ChatBox;
