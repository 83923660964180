import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getAuth, signOut } from "firebase/auth";
import ApiAuth from "../../API/ApiAuth";
import Auth from "../../Redux/Actions/Auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {removeTokenUser} from "../../Redux/variables";

const Sidebar = (props) => {
  const dispatch = useDispatch
  /*const auth = getAuth();*/
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const signout = () => {
    ApiAuth.logout({}).then((response)=>{
      console.log(response,'Success')
      removeTokenUser()
      dispatch(Auth.logout({}))
      toast.success(response.message)
     window.location.href = '/'
      setCurrentUser(null);
    }).catch((error)=>{
      //dispatch(Auth.logout({}))
      toast.error(error)
      removeTokenUser()
      window.location.href = '/'
    })
    /*return
    signOut(auth)
      .then(() => {
        setCurrentUser(null);
        props.history.push("/");
      })
      .catch((error) => {
        // An error happened.
      });*/
  };
  return (
    <>
      <ul className="list-group">
        <li className="list-group-item d-none">
          <Link className={"nav-link"} to={`/subscriptions`}>
            Subscriptions
          </Link>
        </li>
        <li className="list-group-item">
          <Link className={"nav-link"} to={`/request-history`}>
            Request History
          </Link>
        </li>

        <li className="list-group-item">
          <Link className={"nav-link"} to={`/profile`}>
            Profile
          </Link>
        </li>
        <li className="list-group-item">
          <a className={"nav-link"} href="javascript:void(0)" onClick={signout}>
            Logout
          </a>
        </li>
      </ul>
    </>
  );
};
export default Sidebar;
