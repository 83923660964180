import React, {useEffect} from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import {Link} from "react-router-dom"
import { isMobile } from '../utils'
import Lottie from "react-lottie";
import homeanimation from ".././assets/animations/homescreen/homeanimation.json";
import {GoogleView} from "../Helper/GoogleView";
import {Helmet} from 'react-helmet'
import {getPageTitle} from '../Redux/variables'
import TermAndCondition from "../components/TermAndCondition";

function TermsConditions(props) {

 

  const defaultAnimationOptions = {
    // background:
    //   "radial-gradient(48.68% 52.38% at 51.53% 48.33%, #FE6060 0%, #C62525 100%), radial-gradient(43.6% 45.97% at 50.07% 47.98%, #FD8181 0%, #ED4949 38.84%, #E32C2C 86.81%)",
    loop: true,
    autoplay: true,
    animationData: homeanimation ,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(()=>{
    GoogleView('Term and Conditions','/terms-and-conditions')
  },[])

  return (
      <>
          <Helmet>
          <title>Terms and Condition {getPageTitle()}</title>
<meta name="description" content="" />
{/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Home | PropSure" />
<meta property="og:description" content="" />
<meta property="og:url" content="https://www.propsure.com.pk/" />
<meta property="og:site_name" content="PropSure" />
<meta property="article:publisher" content="" />
<meta property="og:image" content="" />
<meta name="twitter:card" content="summary_large_image" />
    </Helmet>
        <Header state={props}/>
        <section>
          <div className="container-fluid ist-section grey-color-1">
            <div className="row">
              <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12 real-estate"
                  style={{ padding: 0 }}
              >
                <div className="realestate-ist" style={{ paddingBottom: 35 }}>
                  <h1
                      className={`main-head ${isMobile() ? "fs-1" : "fs-1"}`}
                      style={{
                        marginTop: isMobile() ? "112px" : "",
                        padding: isMobile() ? 0 : "",
                      }}
                  >
                    Terms & <span>Conditions</span>
                  </h1>
                  <p className={`main-para ${isMobile() ? "px-2" : "pe-5"}`}>
                    <h4 className='fw-bold'>Welcome To PropSure</h4>
                    These terms and conditions outline the rules and regulations for the use of PropSure's Website, located at <Link to="/"><Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></Link>
                  </p>
                  <p className={`main-para ${isMobile() ? "px-2" : "pe-5"}`}>
                    By accessing this Website, we assume that you have accepted these terms and conditions. Do not continue to use <Link to="/"><Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></Link> if you do not agree to all  the terms and conditions stated on this page.
                  </p>

                </div>
              </div>
              <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{ padding: 0 }}
              >
                <div
                    style={{
                      margin: "auto",
                      background:
                          "linear-gradient(to right,#96286D,#C33352,#EF3E36) ",
                          // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                    }}
                >
                  <Lottie options={defaultAnimationOptions} />
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className='container-fluid ist-section grey-color-2'>
          <div className='container py-3'>
            <TermAndCondition/>
          </div>
        </section>
        {/* REAL ESTATE SECTION ENDS HERE */}
        <Footer />
      </>
  );

}

export default TermsConditions
