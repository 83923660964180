import {Link} from "react-router-dom";
import React from "react";

const TermAndCondition = (props) => {

    return (
        <>
            <p>The meaning of the following terms have been ascribed to them for the purpose of these terms & conditions: </p>
            <ul>
                <li>"Client", "You" and "Your" refers to you, the person logging on to this Website and compliant to the Company’s terms and conditions.</li>
                <li>“Website or Our Website”, refers to <Link to="/"><Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></Link></li>
                <li>"The Company", "Ourselves", "We", "Our" and "Us", refers to our Company (PropSure Digital Solutions (Pvt) Limited).</li>
                <li>"Party", "Parties", or "Us", refers to both the Client and the Company.</li>
                <li>“Services” refers to the services which are provided through our Website and includes the following:
                    <ol type="i">
                        <li>Online Property Verification Service (OPVS)</li>
                        <li>Property Inspection Services (PINS)</li>
                        <li>Feasibility Studies </li>
                        <li>Forensic Audit</li>
                        <li>Technical Advisory</li>
                        <li>Data As a Service</li>
                    </ol>
                </li>
                <li>All terms refer to the offer, acceptance and consideration of payments necessary to undertake the process of our assistance to the Client in the most appropriate manner for the expressed purpose of meeting the Client’s need in respect of providing the Client, with the Company’s stated services, in accordance with and subject to the prevailing laws of Pakistan. </li>
                <li>Any use of the above terms or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore refer to the same.</li>
            </ul>

            <h4 className='fw-bold'>Cookies</h4>
            <p>We employ the use of cookies. By accessing <Link to="/"><Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></Link>, you will be accepting our use of cookies in accordance with the PropSure's Privacy Policy.</p>
            <p>Most interactive websites use cookies to let them retrieve the user’s details for each visit to improve the standard of their services. Cookies are used by our Website to enable the functionality of certain areas to make it easier for people visiting our Website. Some of our affiliate/advertising partners may also use cookies.</p>

            <h4 className='fw-bold'>License</h4>
            <p>Unless otherwise stated, PropSure and/or its licensors own the intellectual property rights for all material on <Link to="/"><Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></Link>. All intellectual property rights are reserved. You may access this from <Link to="/"><Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></Link> for your own personal use subjected to restrictions set in these terms and conditions.</p>
            <p>You must not:
                <ul>
                    <li>Republish material from <Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></li>
                    <li>Sell, rent or sub-license material from <Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link> </li>
                    <li>Reproduce, duplicate or copy material from <Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link> </li>
                    <li>Redistribute content from <Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link> </li>
                    <li>Use the data/content available on <Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link> for any commercial purposes.</li>
                    <li>Copy, plagiarize and/or forge material from  <Link to="/"><Link to="/">https://www.propsure.com.pk/</Link></Link></li>
                </ul>
            </p>
            <p>The terms and conditions will be effective from December 1, 2021.</p>
            <p>Parts of our Website offer an opportunity for users to post and exchange opinions and information in certain areas of the Website. PropSure does not filter, edit, publish or review comments prior to their appearance on the Website. Comments do not reflect the views and opinions of PropSure, its agents and/or affiliates. Comments reflect the personal views and opinions of the person who posts the same. To the extent permitted by applicable laws, PropSure shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this Website.</p>
            <p>PropSure reserves the right to monitor all Comments and to remove any Comments which may be considered inappropriate, offensive, insensitive, defamatory or amounts to breach of these Terms and Conditions.</p>
            <p>PropSure also reserves the right to block or terminate the account of any person who violates these terms and conditions.</p>
            <p>You warrant and represent that:
                <ul>
                    <li>You are entitled to post  Comments on our Website and have all necessary licenses and permissions to do so;</li>
                    <li>The Comments do not invade any intellectual property right, including but not limited to  copyright, patent or trademark of any third party;</li>
                    <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy;</li>
                    <li>The Comments will not be used to solicit, promote business, , present commercial activities or unlawful activity.</li>
                </ul>
            </p>
            <p>You hereby grant PropSure a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
            <h4 className='fw-bold'>Payments:</h4>
            <p>By agreeing to avail our services available on <Link to="/">https://www.propsure.com.pk/</Link>, you are agreeing to the service charges for availing the Services. You need to follow the available link in order to proceed with the payment method wherein the exact charges for each of the Services are mentioned.</p>

            <h4 className="fw-bold">Hyperlinking to our Content</h4>
            <p>The following organizations may be linked to our Website without prior written approval:</p>
            <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the websites of other listed businesses; and</li>
                <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
            </ul>
            <p>These organizations may link to our home page, to publications (including newspapers, journals etc.) and/or to other websites, so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>
            <p>
                We may consider and approve other link requests from the following types of organizations:
                <ul>
                    <li>commonly-known consumer and/or business information sources;</li>
                    <li>dot.com community sites;</li>
                    <li>associations or other groups representing charities;</li>
                    <li>online directory distributors;</li>
                    <li>internet portals;</li>
                    <li>accounting, law and consulting firms; and</li>
                    <li>Educational institutions and trade associations.</li>
                </ul>
            </p>
            <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorable to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of PropSure; and (d) the link is in the context of general resource information.</p>

            <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our Website, you must inform us by sending an e-mail to PropSure. Please include your name, your organization’s name, contact information as well as the URL of your website, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
            <p>
                Approved organizations may hyperlink to our Website as follows:
                <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                </ul>
            </p>
            <p>No use of PropSure's logo or other artwork will be allowed for linking  a trademark license agreement.</p>

            <h4 className="fw-bold">iFrames</h4>
            <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

            <h4 className="fw-bold">Content Liability for Hyperlinking</h4>
            <p>We shall not be held responsible for any content that appears on your website. You agree to protect and defend us against all claims that arise as a result of the content on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement of any third party rights.</p>

            <p>
                The data provided is collected and processed from drone images, aerial photographs and/or
                open-source images and is provided for reference only. It is understood and agreed that
                there may be a difference of up
                to <b> 10 to 25%</b> in
                the placement and/or area in the data provided and
                the actual placement and/or area due to the sources from which the data is collected and
                processed. Users are therefore advised to contact the competent authority for confirmation
                of the actual placement and/or area.
            </p>
        </>
    )
}

export  default TermAndCondition;
