import React, {useEffect} from "react";
import Header from "../../components/header/Header";
import "./styles.css";

import Lottie from "react-lottie";
import searchAnimation from "../../assets/animations/FAQs/animations/animation.json";
import online from "../../assets/animations/Services/online.json";
import property from "../../assets/animations/Services/Property.json";
import feasibility from "../../assets/animations/Services/feasibility.json";
import forensic from "../../assets/animations/Services/forensic.json";
import technical from "../../assets/animations/Services/technical.json";
import data from "../../assets/animations/Services/data.json";
import thankYou from "../../assets/animations/Thankyou/Thankyouaniamtion.json";
import ChatBox from "../../components/chatBox/ChatBox";
import { useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'
import {digitalPakistan, getPageTitle} from '../../Redux/variables'

const PropertyInspectionFAQs = (props) => {
  const history = useHistory();
  useEffect(()=>{
    GoogleView('Property Inspection FAQs',`/PropertyInspectionFAQs`)
  },[])
  const search = {
    loop: true,
    autoplay: true,
    animationData: searchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: online,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: thankYou,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: property,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: feasibility,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions5 = {
    loop: true,
    autoplay: true,
    animationData: forensic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions6 = {
    loop: true,
    autoplay: true,
    animationData: technical,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions7 = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
      <>
        <Helmet>
          <title>Property Inspections {getPageTitle()}</title>
          <meta name="description" content="" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home | PropSure" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://www.propsure.com.pk/" />
          <meta property="og:site_name" content="PropSure" />
          <meta property="article:publisher" content="" />
          <meta property="og:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header state={props} />
        <ChatBox />
        {/* FREQUENTLY ASKED QUESTIONS SECTION STSRTS HERE */}
        <section>
          <div className="container-fluid ist-section">
            <div className="grey-color-1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 real-estate">
                  <h1 className="main-head">
                    Frequently <br /> Asked
                    <span>
                  {" "}
                      <br /> Questions
                </span>
                  </h1>
                </div>
                <div
                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
                    style={{ padding: "0%" }}
                >
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                      }}
                  >
                    <Lottie options={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FREQUENTLY ASKED QUESTIONS SECTION ENDS HERE */}
        {/* SERVICES SECTION STSRTS HERE */}
        <section style={{padding:'0px'}} className="second-section FrequentlyAskedQuestions">
          <div className="grey-color-2">
            <div className="container services">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-xs-12 icon-box">
                  <div className="row">
                    {/* <div className="search-area text-center">
                  <input
                    type="search"
                    autoComplete="off"
                    spellCheck="false"
                    role="combobox"
                    placeholder="Search"
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div> */}
                    <div className="catagories-heading text-center">
                      <h4>Categories</h4>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/OnlinePropertyVerificationFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/opvs.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>{digitalPakistan()}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one">
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/w/pins.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Property Inspection Services (PINS)</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/FeasibilityStudyFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/feasibility.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Feasibility Studies</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/ForensicAuditFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/forensic-audit.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Forensic Audit</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/TechnicalAdvisoryFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/technical.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Technical Advisory</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/DataServiceFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/data-as-a-service.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Data As A Service</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-xs-12">
                  <div className="main-two">
                    <h1>
                      Property Inspection <span>Services (PINS)</span>
                    </h1>
                    <h5 id="PIP">
                      What is the need for Property Inspection Services (PINS) in
                      Pakistan?
                    </h5>
                    <p>
                      About 44% of real estate global customers were unsatisfied
                      with their property deals in the year 2013 and regretted their
                      decisions. Lack of access to verified and correct property
                      information coupled with complex ownership transfer processes
                      were the primary reasons behind poor decision-making. One of
                      the significant problems in the housing sector is the
                      construction of houses and buildings without the approval of
                      Regulatory/Building Control Authorities in
                      unplanned/unapproved areas and housing schemes. In Pakistan,
                      out of the total urban housing demand, about 25 percent is
                      being fulfilled by illegal unplanned slums (Katchi Abadis), 60
                      percent through subdivision and utilization of agricultural
                      land (mostly without proper planning and approvals) and 15
                      percent through increasing population density in the existing
                      cities.
                    </p>
                    <p>
                      A total of 5,492 unregistered and illegal housing societies
                      were identified throughout Pakistan in the year 2018. They
                      were mostly undeveloped, while some amongst them existed only
                      on paper. Transactions of unapproved/illegal properties are
                      often found to be fraudulent, resulting in the creation of
                      disputes and police cases that end up in courts. More than one
                      million land-related disputes are pending decisions in the
                      Pakistani courts, comprising 40 percent of all court cases.
                      Billions of rupees of investors, mostly overseas Pakistanis,
                      expatriates and pensioners, get trapped due to bad property
                      transactions and pending court cases. In addition,
                      insufficient judges, overburdened courts, and crafty tactics
                      played by the lawyers result in lengthy proceedings. It can
                      take anything between 15 to 60 years to finally receive a
                      resolution on property-related cases at the Supreme Court,
                      costing fortunes of money and hard work. In light of the
                      stated state of affairs, property inspection becomes vital as
                      it ensures safe investments and prevention of property frauds.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="PIPC">
                      What kind of Property Inspection Services (PINS) does PropSure
                      offer?
                    </h5>
                    <p>PropSure conducts the following types of inspections: </p>
                    <h5>1. Basic Property Report </h5>
                    <p>
                      This report provides information about the plot/property
                      location, size, covered area, type of land use, ownership
                      area, the status of approval, and concerned regulatory
                      authority.
                    </p>
                    <h5>2. Master Plan Report</h5>
                    <p>
                      This report covers the status of the property with reference
                      to provisions of the master plan of that area/scheme regarding
                      the permissible land use and applicable limitations if any.
                    </p>
                    <h5>3. Regulatory Report</h5>
                    <p>
                      This report provides information about the status and
                      approvals of the plot and building/structure constructed over
                      it, by the regulatory/building control authority.
                    </p>
                    <h5>4. Bylaws Report</h5>
                    <p>
                      This report informs about the applicable bylaws, plot details,
                      the FAR (Floor Area Ratio) allowed, the concerned Building
                      Control Authority, and the allowable footprint of the building
                      inside the plot.
                    </p>
                    <h5>5. Demarcation Report</h5>
                    <p>
                      This report presents information about the geographic
                      location, size, layout and detail of adjacent features,
                      demarcation attributes and general information of the
                      property.
                    </p>
                    <h5>6. Livability Report</h5>
                    <p>
                      The report covers an analysis of livability indicators such as
                      accessibility to the city road network, amenities and other
                      essential living requirements (such as commercial
                      area/markets, restaurants, schools, hospitals, parks and
                      club), security situation and arrangements, character of the
                      area, development and habitation level, and state of
                      utilities.
                    </p>
                    <h5>7. Utility Report</h5>
                    <p>
                      The report provides information about the availability of
                      utility services at the proposed property such as electricity,
                      Sui gas, water supply, sewerage, telephone landline, waste
                      disposal, internet and cable TV.
                    </p>
                    <h5>8. Valuation Report</h5>
                    <p>
                      The report presents information about the market values of the
                      said property that includes land value, building construction
                      cost, sale value and total assessment.
                    </p>
                    <h5>9. Structural Report</h5>
                    <p>
                      Details about structural stability, robustness and safety are
                      provided in this report. The information is based on the
                      physical inspection, non-destructive testing and analysis of
                      building components like foundation, columns, walls, beams,
                      slabs, and stairs.
                    </p>
                    <h5>10. Non-Structural Report</h5>
                    <p>
                      The report covers information about the non-structural
                      features of the proposed property/building such as finishing
                      work, facade, roof condition, rainwater drainage,
                      leakage/seepage, water supply system, electrical system,
                      openings and ventilation, and landscaping.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SERVICES SECTION ENDS HERE */}
        {/* FOOTER SECTION STSRTS HERE */}

        <ScrollButton />
        <Footer />
        {/* <section className="FrequentlyAskedQuestions-footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                className="img-fluid w-25"
                src={require("../../assets/img/Logo 2.png").default}
                alt="Logo"
              />
              <div className="para">
                <p>
                  PropSure Digital Solutions (Pvt) Ltd 4th Floor, Beverly
                  Centre, Blue Area, Islamabad
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="contact">
                <p>
                  Contact us at{" "}
                  <b style={{ fontWeight: "bold" }}> info@propsure.com.pk</b>{" "}
                  <br />
                  to get in touch with us.
                </p>
                <p>
                  <i className="fa fa-phone" style={{ fontSize: 20 }} />{" "}
                  051-8442244 <br />
                  021-34541403 <br />
                  042-36407379
                </p>
              </div>
              <div className="contact-icons">
                <i
                  className="fa fa-twitter"
                  aria-hidden="true"
                  style={{ paddingRight: "4%" }}
                />
                <i className="fa fa-instagram" style={{ paddingRight: "4%" }} />
                <i className="fa fa-facebook" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </>
  );
};

export default PropertyInspectionFAQs;
