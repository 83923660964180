import React, { useContext, useEffect, useState } from "react";
import "./styles.css";

import Lottie from "react-lottie";

import online from "../../assets/animations/Services/online.json";
import property from "../../assets/animations/Services/Property.json";
import feasibility from "../../assets/animations/Services/feasibility.json";
import forensic from "../../assets/animations/Services/forensic.json";
import technical from "../../assets/animations/Services/technical.json";
import data from "../../assets/animations/Services/data.json";
import thankYou from "../../assets/animations/Thankyou/Thankyouaniamtion.json";
import { isMobile } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { Redirect, useHistory } from "react-router-dom";
import {digitalPakistan, locateYourProperty} from "../../Redux/variables";

const Services = (props) => {
  const history = useHistory();
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: online,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: thankYou,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: property,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: feasibility,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions5 = {
    loop: true,
    autoplay: true,
    animationData: forensic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions6 = {
    loop: true,
    autoplay: true,
    animationData: technical,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions7 = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [opvVisible, setOpvVisible] = useState(false);
  const [opvThankYouVisible, setOpvThankYouVisible] = useState(false);
  const [piVisible, setPiVisible] = useState(false);
  const [piThankYouVisible, setPiThankYouVisible] = useState(false);
  const [fsVisible, setFsVisible] = useState(false);
  const [fsThankYouVisible, setFsThankYouVisible] = useState(false);
  const [faVisible, setFaVisible] = useState(false);
  const [taVisible, setTaVisible] = useState(false);
  const [dsVisible, setDsVisible] = useState(false);

  const {
    currentUser,
    setCurrentUser,
    loggedIn,
    setLoggedIn,
    mapClicked,
    setMapClicked,
  } = useContext(AuthContext);

  const handleOpvSubmit = (event) => {
    event.preventDefault();
    setOpvVisible(false);
    setOpvThankYouVisible(true);
  };
  const handlePiSubmit = (event) => {
    event.preventDefault();
    setPiVisible(false);
    setPiThankYouVisible(true);
  };
  const handleFsSubmit = (event) => {
    event.preventDefault();
    setFsVisible(false);
    setFsThankYouVisible(true);
  };
  const checkUser = () => {
    if (currentUser) {
      setLoggedIn(true);
      // setOpvVisible(!opvVisible);
    } else {
      setLoggedIn(false);
      setMapClicked(true);
    }
  };

  return (
    <>
      {loggedIn && <Redirect to={"/map"} />}
      {/* SERVICES SECTION STSRTS HERE */}
      <section className="second-section grey-color-2" id="Services-section">
        <div className="container services">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
              style={{ padding: 0 }}
            >
              <div className={isMobile() ? "" : "row"}>
                <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-4 icon-box">
                  <div className="group-one text-center">
                    <div
                      onClick={() => {
                        // checkUser();
                        setOpvVisible(!opvVisible);
                      }}
                      className="btn"
                      style={{
                        margin: "auto",
                      }}
                    >
                      <img className={'img-box-mws'} src={require("../../assets/img/services/opvs.svg").default} alt=""/>
                      {/*<Lottie options={animationOptions1} />*/}
                    </div>
                  </div>
                  <div>
                    <div
                      className="form-button"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        onClick={() => {
                          // checkUser();
                          setOpvVisible(!opvVisible);
                        }}
                        className="btn py-1 px-0"
                        data-bs-toggle="modal"
                        data-bs-target="#OnlinePropertyVerification"
                      >{digitalPakistan()}
                      </button>
                    </div>

                    <div
                      style={{
                        display: opvVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setOpvVisible(!opvVisible)}
                              />
                              <h4>
                                Online Property Verification
                                <span>
                                  <br /> System (OPVS)
                                </span>
                              </h4>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
                                  {/* <form onSubmit={handleOpvSubmit}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                      >
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="adress"
                                        className="form-label"
                                      >
                                        Street Adress of Property to Verify
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="adress"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="phnumber"
                                        className="form-label"
                                      >
                                        Phone Number
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="phnumber"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Email
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        required
                                      />
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-check"
                                        // required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="remember-check"
                                      >
                                        Send me updates about PropSure’s
                                        projects and services.{" "}
                                        <span> Privacy Policy</span>
                                      </label>
                                    </div>
                                    <div className="mt-3 d-grid">
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="submit"
                                        data-bs-toggle="modal"
                                        data-bs-target="#OnlinePropertyVerification-submitForm"
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  </form> */}
                                  <div className="services-model">
                                    <div className="d-flex">
                                      <p>
                                        {/* <h1

                                      ></h1> */}
                                        <div
                                          style={{
                                            // position: "fixed",
                                            marginTop: 12,
                                          }}
                                        ></div>
                                        PropSure Online Property Verification
                                        System (OPVS) covers validation of
                                        certain attributes of data such as
                                        Layout Plan (LOP) Approval Status,
                                        Competent Authority (for dealing
                                        approvals in its jurisdiction area),
                                        Address (Plot No, Street No, Gali No,
                                        Road Name), Plot LOP Approval Status,
                                        Total Area (Sq. Yds) and Type of Land
                                        Use permitted in Approved Layout Plan.
                                        <br />
                                        <button
                                          className="property-btn"
                                          style={{
                                            marginLeft: "-3px",
                                            marginTop: "15px",
                                            width: "200px",
                                            height: "40px",
                                          }}
                                          onClick={() => checkUser()}
                                        >{locateYourProperty()}
                                        </button>
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 mt-3">
                                        <img
                                          src={
                                            require("../../assets/img/logo_main.png")
                                              .default
                                          }
                                          alt="..."
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3 mt-3">
                                        <p>
                                          Call for Consultation <br />
                                          <b>PropSure Digital Solutions</b>{" "}
                                          <br />
                                          Phone: 051-8442244, 021-34541403,
                                          042-36407379 <br />
                                          Email: info@propsure.com.pk
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 ">
                                  <div className="services-model">
                                    <h3>
                                      <b>FAQ'S</b>
                                    </h3>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/OnlinePropertyVerificationFAQs"
                                            )
                                          }
                                          href="#SOPV"
                                        >
                                          What is the significance of Online
                                          Property Verification System (OPVS)?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/OnlinePropertyVerificationFAQs"
                                            )
                                          }
                                          href="#OPVSO"
                                        >
                                          What Online Property Verification
                                          System (OPVS) does PropSure offer?
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* Modal */}
                    <div
                      style={{
                        display: opvThankYouVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // id="OnlinePropertyVerification-submitForm"
                      className="modal"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body media-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setOpvThankYouVisible(false)}
                              />
                              <h4 style={{ paddingRight: "6%" }}>
                                Online
                                <span>
                                  Property Verification
                                  <br /> System (OPVS)
                                </span>
                              </h4>
                              <div style={{ padding: "1%" }}>
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <Lottie
                                    options={animationOptions2}
                                    height={80}
                                    width={80}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                  <div className="media-content text-center">
                                    <h1>
                                      Thank you for filling out your
                                      information!
                                    </h1>
                                    <p>
                                      One of our representatives will be in
                                      touch with you soon. <br />
                                      Meanwhile, please feel free to download
                                      our monthly newsletter to get updated on
                                      all our recent activities.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 icon-box">
                  <div className="group-one text-center">
                    <div
                      onClick={() => {
                        // checkUser();
                        setPiVisible(true);
                      }}
                      className="btn"
                      style={{
                        margin: "auto",
                      }}
                    >
                      <img className={'img-box-mws'} src={require("../../assets/img/services/pins.svg").default} alt=""/>
                     {/* <Lottie options={animationOptions3} />*/}
                    </div>
                  </div>
                  <div>
                    <div
                      className="form-button"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        onClick={() => {
                          // checkUser();
                          setPiVisible(true);
                        }}
                        className="btn py-1 px-0"
                        data-bs-toggle="modal"
                        data-bs-target="#PropertyInspection"
                      >
                        Property Inspection Services (PINS)
                      </button>
                    </div>

                    <div
                      style={{
                        display: piVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                    >
                      <div className="modal-dialog property-pins modal-xl">
                        <div className="modal-content">
                          <div className="modal-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => {
                                  setPiVisible(false);
                                }}
                              />
                              <h4>
                                Property Inspection
                                <br />
                                <span> Services (PINS)</span>
                              </h4>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
                                  {/* <form onSubmit={handlePiSubmit}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="username"
                                        className="form-label"
                                      >
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="TypeofPropertyInspection"
                                        className="form-label"
                                      >
                                        Type of Property Inspection
                                      </label>
                                      <select
                                        defaultValue={"Basic Property Report"}
                                        className="form-select"
                                        id="TypeofPropertyInspection"
                                        required
                                      >
                                        <option>Basic Property Report</option>
                                        <option value={1}>One</option>
                                        <option value={2}>Two</option>
                                        <option value={3}>Three</option>
                                      </select>
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="StreetAdress"
                                        className="form-label"
                                      >
                                        Street Adress of Property to Inspect
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="StreetAdress"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="phnumber"
                                        className="form-label"
                                      >
                                        Phone Number
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="phnumber"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Email
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        required
                                      />
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-check"
                                        // required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="remember-check"
                                      >
                                        Send me updates about PropSure’s
                                        projects and services. Privacy Policy
                                      </label>
                                    </div>
                                    <div className="mt-3 d-grid">
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="submit"
                                        data-bs-toggle="modal"
                                        // data-bs-target="#PropertyInspection-submitForm"
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  </form> */}
                                  <div className="services-model">
                                    <div className="d-flex">
                                      <p>
                                        <h3>
                                          PropSure offers the following types of
                                        </h3>
                                        <h5> Property Inspection Services:</h5>
                                        <h6>1. Basic Property Report </h6>
                                        <h6>2. Master Plan Report </h6>
                                        <h6>3. Regulatory Report </h6>
                                        <h6>4. Bylaws Report </h6>
                                        <h6>5. Demarcation Report </h6>
                                        <h6>6. Livability Report </h6>
                                        <h6>7. Utility Report </h6>
                                        <h6>8. Valuation Report </h6>
                                        <h6>9. Structural Report </h6>
                                        <h6>10. Non-Structural Report </h6>
                                        {/* < br/> */}
                                        <button
                                          className="property-btn mt-4 mb-0"
                                          style={{
                                            marginLeft: "-3px",
                                            marginTop: "8px",
                                            width: "200px",
                                            height: "40px",
                                          }}
                                          // onClick={() => checkUser()}
                                        >
                                          Request For PINS
                                        </button>
                                        <div
                                          style={{
                                            // position: "fixed",
                                            marginTop: 12,
                                          }}
                                        ></div>
                                        {/* We at PropSure are committed towards bringing a technology-driven change
                                        in the real estate sector of Pakistan. We understand the changing dynamics
                                        of our modern world and the need to cope up with them in order to stay
                                        relevant and competitive. Our services are based-on disruptive technologies
                                        which cater to the unique challenges of real estate in Pakistan. */}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 mt-3">
                                        <img
                                          src={
                                            require("../../assets/img/logo_main.png")
                                              .default
                                          }
                                          alt="..."
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3 mt-3">
                                        <p>
                                          Call for Consultation <br />
                                          <b>PropSure Digital Solutions</b>{" "}
                                          <br />
                                          Phone: 051-8442244, 021-34541403,
                                          042-36407379 <br />
                                          Email: info@propsure.com.pk
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                  <div className="services-model">
                                    <h3>
                                      <b>FAQ'S</b>
                                    </h3>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/PropertyInspectionFAQs"
                                            )
                                          }
                                          href="#PIP"
                                        >
                                          What is the need for Property
                                          Inspection Services (PINS) in
                                          Pakistan?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/PropertyInspectionFAQs"
                                            )
                                          }
                                          href="#PIPC"
                                        >
                                          What kind of Property Inspection
                                          Services (PINS) does PropSure offer?
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Modal */}
                    <div
                      style={{
                        display: piThankYouVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // id="OnlinePropertyVerification-submitForm"
                      className="modal"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body media-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setPiThankYouVisible(false)}
                              />
                              <h4 style={{ paddingRight: "6%" }}>
                                Property
                                <span>Inspection Services (PINS)</span>
                              </h4>
                              <div style={{ padding: "1%" }}>
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <Lottie
                                    options={animationOptions2}
                                    height={80}
                                    width={80}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                  <div className="media-content text-center">
                                    <h1>
                                      Thank you for filling out your
                                      information!
                                    </h1>
                                    <p>
                                      One of our representatives will be in
                                      touch with you soon. <br />
                                      Meanwhile, please feel free to download
                                      our monthly newsletter to get updated on
                                      all our recent activities.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 icon-box">
                  <div className="group-one text-center">
                    <div
                      onClick={() => setFsVisible(true)}
                      className="btn"
                      style={{
                        margin: "auto",
                      }}
                    >
                      <img className={'img-box-mws'} src={require("../../assets/img/services/feasibility.svg").default} alt=""/>
                     {/* <Lottie options={animationOptions4} />*/}
                    </div>
                  </div>
                  <div>
                    {/* Button trigger modal */}
                    <div
                      className="form-button"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        onClick={() => setFsVisible(true)}
                        className="btn py-1 px-0"
                        data-bs-toggle="modal"
                        data-bs-target="#FeasibilityStudies"
                      >
                        Feasibility Studies
                      </button>
                    </div>
                    {/* Modal */}
                    <div
                      style={{
                        display: fsVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // className="modal fade"
                      // id="FeasibilityStudies"
                      // tabIndex={-1}
                      // aria-labelledby="FeasibilityStudies"
                      // aria-hidden="true"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setFsVisible(false)}
                              />
                              <h4>
                                Feasibility <br />
                                <span>Studies</span>
                              </h4>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
                                  {/* <form onSubmit={handleFsSubmit}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                      >
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="TypeofFeasibilityStudy"
                                        className="form-label"
                                      >
                                        Type of Feasibility Study
                                      </label>
                                      <select
                                        defaultValue={"Screening Study"}
                                        className="form-select"
                                        id="TypeofFeasibilityStudy"
                                        required
                                      >
                                        <option>Screening Study</option>
                                        <option value={1}>One</option>
                                        <option value={2}>Two</option>
                                        <option value={3}>Three</option>
                                      </select>
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="phnumber"
                                        className="form-label"
                                      >
                                        Phone Number
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="phnumber"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Email
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        required
                                      />
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-check"
                                        // required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="remember-check"
                                      >
                                        Send me updates about PropSure’s
                                        projects and services.{" "}
                                        <span> Privacy Policy</span>
                                      </label>
                                    </div>
                                    <div className="mt-3 d-grid">
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="submit"
                                        data-bs-toggle="modal"
                                        data-bs-target="#FeasibilityStudies-submitForm"
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  </form> */}
                                  <div className="services-model">
                                    <div className="d-flex">
                                      <p>
                                        {/* <h1

                                      >Introduction OPVS</h1> */}
                                        <div
                                          style={{
                                            // position: "fixed",
                                            marginTop: 2,
                                          }}
                                        ></div>
                                        Any project’s success or failure depends
                                        primarily upon four specific parameters
                                        (i) Ownership, (ii) Approval, (iii)
                                        Demand and (iv) Delivery. Demand is a
                                        very technical subject and it is
                                        identified with the help of empirical
                                        data in feasibility study. Feasibility
                                        studies provide stakeholders with the
                                        specific knowledge, understanding of the
                                        proposed project and available best
                                        options that are helpful in making
                                        important investment decisions. It all
                                        happens through careful survey,
                                        evaluation and analysis of the market
                                        size and key players in site proximity,
                                        site potential, customer demand, market
                                        supply position, competitors, possible
                                        risks and roadblocks.
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 mt-3">
                                        <img
                                          src={
                                            require("../../assets/img/logo_main.png")
                                              .default
                                          }
                                          alt="..."
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3 mt-3">
                                        <p>
                                          Call for Consultation <br />
                                          <b>PropSure Digital Solutions</b>{" "}
                                          <br />
                                          Phone: 051-8442244, 021-34541403,
                                          042-36407379
                                          <br />
                                          Email: info@propsure.com.pk
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                  <div className="services-model">
                                    <h3>
                                      <b>FAQ'S</b>
                                    </h3>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/FeasibilityStudyFAQs"
                                            )
                                          }
                                          href="#WNFS"
                                        >
                                          What are the main parameters that
                                          define the success of a project?
                                        </a>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/FeasibilityStudyFAQs"
                                            )
                                          }
                                          href="#WIFS"
                                        >
                                          What is the importance of feasibility
                                          studies in the overall success of a
                                          project?
                                        </a>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/FeasibilityStudyFAQs"
                                            )
                                          }
                                          href="#WKFS"
                                        >
                                          What kind of feasibility study
                                          services does PropSure offer?
                                        </a>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/FeasibilityStudyFAQs"
                                            )
                                          }
                                          href="#WFSCP"
                                        >
                                          What are some of the feasibility
                                          studies completed by PropSure?
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Modal */}
                    <div
                      style={{
                        display: fsThankYouVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // id="OnlinePropertyVerification-submitForm"
                      className="modal"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body media-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setFsThankYouVisible(false)}
                              />
                              <h4 style={{ paddingRight: "6%" }}>
                                Feasibility
                                <span>Study</span>
                              </h4>
                              <div style={{ padding: "1%" }}>
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <Lottie
                                    options={animationOptions2}
                                    height={80}
                                    width={80}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                  <div className="media-content text-center">
                                    <h1>
                                      Thank you for filling out your
                                      information!
                                    </h1>
                                    <p>
                                      One of our representatives will be in
                                      touch with you soon. <br />
                                      Meanwhile, please feel free to download
                                      our monthly newsletter to get updated on
                                      all our recent activities.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 icon-box">
                  <div className="group-one text-center">
                    <div
                      onClick={() => setFaVisible(true)}
                      className="btn"
                      style={{
                        margin: "auto",
                      }}
                    >
                      <img className={'img-box-mws'} src={require("../../assets/img/services/forensic-audit.svg").default} alt=""/>
                    {/*  <Lottie options={animationOptions5} />*/}
                    </div>
                  </div>
                  <div>
                    {/* Button trigger modal */}
                    <div
                      className="form-button"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        onClick={() => setFaVisible(true)}
                        className="btn py-1 px-0"
                        data-bs-toggle="modal"
                        data-bs-target="#ForensicAudit"
                      >
                        Forensic Audit
                      </button>
                    </div>
                    {/* Modal */}
                    <div
                      style={{
                        display: faVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // className="modal fade"
                      // id="ForensicAudit"
                      // tabIndex={-1}
                      // aria-labelledby="ForensicAudit"
                      // aria-hidden="true"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body media-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setFaVisible(false)}
                              />
                              <h4>
                                Forensic <br />
                                <span>Audit</span>
                              </h4>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
                                  <div className="media-content">
                                    <h1>Initiating a Forensic Audit</h1>
                                    <p>
                                      PropSure offers Forensic and Investigative
                                      Audit Services in Pakistan.
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 mt-3">
                                      <img
                                        src={
                                          require("../../assets/img/logo_main.png")
                                            .default
                                        }
                                        alt="..."
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 mt-3">
                                      <p>
                                        Call for Consultation <br />
                                        <b>PropSure Digital Solutions</b> <br />
                                        Phone: 051-8442244, 021-34541403,
                                        042-36407379 <br />
                                        Email: info@propsure.com.pk
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                  <div className="services-model">
                                    <h3>
                                      <b>FAQ'S</b>
                                    </h3>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/ForensicAuditFAQs")
                                          }
                                          href="#WNFAP"
                                        >
                                          Why forensic audit?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/ForensicAuditFAQs")
                                          }
                                          href="#WTFASPO"
                                        >
                                          Why should you choose PropSure to
                                          conduct a Forensic Audit?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/ForensicAuditFAQs")
                                          }
                                          href="#WPCFA"
                                        >
                                          Which forensic audits have been
                                          completed by the Company?
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 icon-box">
                  <div className="group-one text-center">
                    <div
                      onClick={() => setTaVisible(true)}
                      className="btn"
                      style={{
                        margin: "auto",
                      }}
                    >
                      <img className={'img-box-mws'} src={require("../../assets/img/services/technical.svg").default} alt=""/>
                      {/*<Lottie options={animationOptions6} />*/}
                    </div>
                  </div>
                  <div>
                    {/* Button trigger modal */}
                    <div
                      className="form-button"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        onClick={() => setTaVisible(true)}
                        className="btn py-1 px-0"
                        data-bs-toggle="modal"
                        data-bs-target="#TechnicalAdvisory"
                      >
                        Technical <br />
                        Advisory
                      </button>
                    </div>
                    {/* Modal */}
                    <div
                      style={{
                        display: taVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // className="modal fade"
                      // id="TechnicalAdvisory"
                      // tabIndex={-1}
                      // aria-labelledby="TechnicalAdvisory"
                      // aria-hidden="true"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body media-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setTaVisible(false)}
                              />
                              <h4>
                                Technical <br />
                                <span>Advisory</span>
                              </h4>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
                                  <div className="media-content">
                                    <h3>Acquire Technical Advisory Services</h3>
                                    <p>
                                      PropSure Digital Solutions through its
                                      highly skilled, experienced, and dedicated
                                      experts and professionals provides
                                      technical advisory services to deal with
                                      design and documentation related matters
                                      for project approvals.
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 mt-3">
                                      <img
                                        src={
                                          require("../../assets/img/logo_main.png")
                                            .default
                                        }
                                        alt="..."
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 mt-3">
                                      <p>
                                        Call for Consultation <br />
                                        <b>PropSure Digital Solutions</b> <br />
                                        Phone: 051-8442244, 021-34541403,
                                        042-36407379 <br />
                                        Email: info@propsure.com.pk
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                  <div className="services-model">
                                    <h3>
                                      <b>FAQ'S</b>
                                    </h3>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/TechnicalAdvisoryFAQs"
                                            )
                                          }
                                          href="#WITAS"
                                        >
                                          What is the importance of technical
                                          advisory services provided by
                                          PropSure?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/TechnicalAdvisoryFAQs"
                                            )
                                          }
                                          href="#WKTASPO"
                                        >
                                          What kind of technical advisory
                                          services does PropSure offer?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push(
                                              "/TechnicalAdvisoryFAQs"
                                            )
                                          }
                                          href="#WSTASPP"
                                        >
                                          What are those projects for which
                                          PropSure has provided technical
                                          advisory services?
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 icon-box">
                  <div className="group-one text-center">
                    <div
                      onClick={() => setDsVisible(true)}
                      className="btn"
                      style={{
                        margin: "auto",
                      }}
                    >
                      <img className={'img-box-mws'} src={require("../../assets/img/services/data-as-a-service.svg").default} alt=""/>
                      {/*<Lottie options={animationOptions7} />*/}
                    </div>
                  </div>
                  <div>
                    {/* Button trigger modal */}
                    <div
                      className="form-button"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        onClick={() => setDsVisible(true)}
                        className="btn py-1 px-0"
                        data-bs-toggle="modal"
                        data-bs-target="#DataAsAservices"
                      >
                        Data As A Service
                      </button>
                    </div>
                    {/* Modal */}
                    <div
                      style={{
                        display: dsVisible ? "inherit" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        overflowY: "scroll",
                        width: "100%",
                        backgroundColor: "#00000070",
                        zIndex: 10,
                      }}
                      // className="modal fade"
                      // id="DataAsAservices"
                      // tabIndex={-1}
                      // aria-labelledby="DataAsAservices"
                      // aria-hidden="true"
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-body media-body">
                            <div className="text-center">
                              <button
                                className="fa fa-angle-left"
                                style={{
                                  float: "left",
                                  fontSize: 25,
                                  marginTop: 20,
                                  marginLeft: 30,
                                  borderRadius: "100%",
                                  padding: "8px 16px",
                                  background:
                                    "linear-gradient(317.38deg, #FFFFFF 14.22%, rgba(246, 249, 255, 0) 106.07%)",
                                  border: "2px solid #384051",
                                  boxSizing: "border-box",
                                }}
                                onClick={() => setDsVisible(false)}
                              />
                              <h4>
                                Data as a<br />
                                <span>Service</span>
                              </h4>
                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
                                  <div className="media-content">
                                    <h1>Initiate request for data</h1>
                                    <p>
                                      The access to Pakistan's biggest spatial
                                      data bank by the stakeholders of the Real
                                      Estate Industry and service providing
                                      companies can be an excellent value
                                      addition for them.
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 mt-3">
                                      <img
                                        src={
                                          require("../../assets/img/logo_main.png")
                                            .default
                                        }
                                        alt="..."
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 mt-3">
                                      <p>
                                        Call for Consultation <br />
                                        <b>PropSure Digital Solutions</b> <br />
                                        Phone: 051-8442244, 021-34541403,
                                        042-36407379 <br />
                                        Email: info@propsure.com.pk
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                  <div className="services-model">
                                    <h3>
                                      <b>FAQ'S</b>
                                    </h3>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/DataServiceFAQs")
                                          }
                                          href="#WILDRE"
                                        >
                                          What is the significance of land data
                                          in real estate?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/DataServiceFAQs")
                                          }
                                          href="#WDSPP"
                                        >
                                          What are the data services provided by
                                          PropSure?
                                        </a>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/DataServiceFAQs")
                                          }
                                          href="#WBSDB"
                                        >
                                          What are the benefits of having access
                                          to PropSure spatial data bank?
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <i className="fa fa-angle-right" />
                                      <div className="flex-grow-1 ms-3">
                                        <a
                                          onClick={() =>
                                            history.push("/DataServiceFAQs")
                                          }
                                          href="#WPUSDB"
                                        >
                                          Who are the potential users of
                                          PropSure spatial data bank?
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
              style={{ padding: 0 }}
            >
              <div className="main-one">
                <h1>Services</h1>
                <p>
                  We at PropSure are committed towards bringing a
                  technology-driven change in the real estate sector of
                  Pakistan. By understanding the ever-changing dynamics of
                  today’s modern world, it has become extremely vital to adapt
                  this change and stay competitive. In an effort to accomplish
                  this objective, we are offering much needed technology
                  oriented services to deal with unique challenges faced by the
                  country’s real estate sector.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* SERVICES SECTION ENDS HERE */}
    </>
  );
};

export default Services;
