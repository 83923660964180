import React from "react";
import "./styles.css";
import Lottie from "react-lottie";
import integrity from "../../assets/animations/Ourvalues/integrity.json";
import respect from "../../assets/animations/Ourvalues/Respect.json";
import innovation from "../../assets/animations/Ourvalues/innovation.json";
import drive from "../../assets/animations/Ourvalues/drive.json";
// import Integrity from "../../assets/img/values/Integrity.svg";
// import Respect from "../../assets/img/values/Respect.svg";
// import Innovation from "../../assets/img/values/Innovation.svg";
// import Reliability from "../../assets/img/values/Reliability.svg";
const OurValues = () => {
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: integrity,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: respect,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: innovation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: drive,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {/* OUR VALUES SECTION STSRTS HERE */}
      <section className="fifth-section grey-color-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="ourvalues-head">
                <h1>
                  Our <span> Values</span>
                </h1>
              </div>
            </div>
            <div className=" col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
              <div className="ourvalues-content text-center">
                <div className="icon text-center">
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%); mix-blend-mode: normal",
                      }}
                  >
                   {/* <img src={Integrity} width="100px" /> */}
                    <Lottie
                        options={animationOptions1}
                        width={100}
                        height={100}
                    />
                  </div>
                  {/* <lottie-player src="./frameworks/Lottie Animation/Ourvalues/integrity.json" background: linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal;  speed="1"  style=" height: 100px; width: 100px; margin: 0 auto;" loop autoplay></lottie-player> */}
                </div>
                <p>Integrity</p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
              <div className="ourvalues-content text-center">
                <div className="icon text-center">
                  {/* <lottie-player src="./frameworks/Lottie Animation/Ourvalues/respect.json" background: linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal;  speed="1"  style=" height: 100px; width: 100px; margin: 0 auto;" loop autoplay></lottie-player> */}
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%); mix-blend-mode: normal;",
                      }}
                  >
                    
                    {/* <img src={Respect} width="100px" /> */}
                    <Lottie
                        options={animationOptions2}
                        width={100}
                        height={100}
                    />
                  </div>
                </div>
                <p>Respect</p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
              <div className="ourvalues-content text-center">
                <div className="icon text-center">
                  {/* <lottie-player src="./frameworks/Lottie Animation/Ourvalues/innovation.json" background: linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal;  speed="1"  style=" height: 100px; width: 100px; margin: 0 auto;" loop autoplay></lottie-player> */}

                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal;",
                      }}
                  >
                    {/* <img src={Innovation} width="100px" /> */}
                    <Lottie
                        options={animationOptions3}
                        width={100}
                        height={100}
                    />
                  </div>
                </div>
                <p>Innovation</p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
              <div className="ourvalues-content text-center">
                <div className="icon text-center">
                  {/* <lottie-player src="./frameworks/Lottie Animation/Ourvalues/drive.json" background: linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal;  speed="1"  style=" height: 100px; width: 100px; margin: 0 auto;" loop autoplay></lottie-player> */}

                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(330.58deg, #D2DBEB 9.3%, #F6F9FF 86.21%); mix-blend-mode: normal;",
                      }}
                  >
                    
                    {/* <img src={Reliability} width="100px" /> */}
                    <Lottie
                        options={animationOptions4}
                        width={100}
                        height={100}
                    />
                  </div>
                </div>
                <p>Drive</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* OUR VALUES SECTION ENDS HERE */}
    </>
  );
};

export default OurValues;
