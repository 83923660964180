import {useRef, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const RightForm = (props) => {
    const [formData,setFormData] = useState({});
    const [loader,setLoader] = useState(false);
    const buttonRef = useRef(null);
    const handleChange = (e) =>{
        const name = e.target.getAttribute('name');
        if (e.target.value){
            setFormData({
                ...formData,[name] :e.target.value
            })
        } else {
            delete formData[name]
            setFormData((formData) => ({ ...formData }))
        }
    }
    const submitHandle = (e) => {
        setLoader(true);
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['token'] = 'API_KEY';
        axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
        axios.post(`https://seminar.propsure.rocks`,formData,{
            withCredentials: false
        }).then((response)=>{
            const {success,message} = response.data
            if (success){
                toast.success(message);
                setFormData((formData) => ({}))
            } else {
                toast.error(message)
            }
            setLoader(false)
        }).catch((error)=>{
            setLoader(false)
        })
    }

    return (

            <div className="event-form col-md-5">
                <div className="form">
                    <h2 className="form-heading">Register Now</h2>
                    <form>
                        <div className="form-group mb-3">
                            <input onChange={handleChange}
                                   value={formData.name??''}
                                   name={'name'} type="text"
                                   className={'form-control'} placeholder={'Name'}/>
                        </div>
                        <div className="form-group mb-3">
                            <input onChange={handleChange}
                                   value={formData.phone??''}
                                   name={'phone'}
                                   type="text" className={'form-control'} placeholder={'Phone No'}/>
                        </div>
                        <div className="form-group mb-3">
                            <input required onChange={handleChange}
                                   value={formData.email??''}
                                   name={'email'} type="email" className={'form-control'} placeholder={'Email'}/>
                        </div>
                        <div className="form-group mb-3">
                            <input onChange={handleChange}
                                   value={formData.company??''}
                                   name={'company'} type="text" className={'form-control'} placeholder={'Profession / Company'}/>
                        </div>
                        <div className="form-group mb-3">
                            <input onChange={handleChange}
                                   value={formData.city??''}
                                   name={'city'} type="text" className={'form-control'} placeholder={'City'}/>
                        </div>
                        <div className="form-group mb-3">
                            <button
                                ref={buttonRef}
                                onClick={submitHandle}
                                disabled={!(Object.keys(formData).length ===5)}
                                type={'button'}
                                className={(!(Object.keys(formData).length ===5)?'disabled submit_button':'submit_button')}
                            >{loader?'Please wait ...':'Register Now'}
                            </button>
                        </div>
                    </form>

                </div>
            </div>

    )
}

export default RightForm