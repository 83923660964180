import React, {useEffect} from "react";
import Header from "../../components/header/Header";
import "./styles.css";
import {digitalPakistan, getPageTitle} from '../../Redux/variables'

import Lottie from "react-lottie";
import searchAnimation from "../../assets/animations/FAQs/animations/animation.json";
import online from "../../assets/animations/Services/online.json";
import property from "../../assets/animations/Services/Property.json";
import feasibility from "../../assets/animations/Services/feasibility.json";
import forensic from "../../assets/animations/Services/forensic.json";
import technical from "../../assets/animations/Services/technical.json";
import data from "../../assets/animations/Services/data.json";
import thankYou from "../../assets/animations/Thankyou/Thankyouaniamtion.json";
import ChatBox from "../../components/chatBox/ChatBox";
import { useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'

const FeasibilityStudyFAQs = (props) => {
  const history = useHistory();
  useEffect(()=>{
    GoogleView('Feasibility Study FAQs',`/FeasibilityStudyFAQs`)
  },[])
  const search = {
    loop: true,
    autoplay: true,
    animationData: searchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: online,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: thankYou,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: property,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: feasibility,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions5 = {
    loop: true,
    autoplay: true,
    animationData: forensic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions6 = {
    loop: true,
    autoplay: true,
    animationData: technical,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions7 = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
      <>
        <Helmet>
          <title>Feasibility Study {getPageTitle()}</title>
          <meta name="description" content="" />
          {/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home | PropSure" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://www.propsure.com.pk/" />
          <meta property="og:site_name" content="PropSure" />
          <meta property="article:publisher" content="" />
          <meta property="og:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header state={props} />
        <ChatBox />
        {/* FREQUENTLY ASKED QUESTIONS SECTION STSRTS HERE */}
        <section>
          <div className="container-fluid ist-section">
            <div className="grey-color-1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 real-estate">
                  <h1 className="main-head">
                    Frequently <br /> Asked
                    <span>
                  <br /> Questions
                </span>
                  </h1>
                </div>
                <div
                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
                    style={{ padding: "0%" }}
                >
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                      }}
                  >
                    <Lottie options={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FREQUENTLY ASKED QUESTIONS SECTION ENDS HERE */}
        {/* SERVICES SECTION STSRTS HERE */}
        <section style={{padding:'0px'}} className="second-section FrequentlyAskedQuestions">
          <div className="grey-color-2">
            <div className="container services">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-xs-12 icon-box">
                  <div className="row">
                    <div className="catagories-heading text-center">
                      <h4>Categories</h4>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/OnlinePropertyVerificationFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/opvs.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>{digitalPakistan()}</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/PropertyInspectionFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/pins.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Property Inspection Services (PINS)</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one">
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/w/feasibility.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Feasibility Studies</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/ForensicAuditFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/forensic-audit.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Forensic Audit</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/TechnicalAdvisoryFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/technical.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Technical Advisory</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/DataServiceFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/data-as-a-service.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Data As A Service</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-xs-12">
                  <div className="main-two">
                    <h1>Feasibility Studies</h1>
                    <h5 id="WNFS">
                      What are the main parameters that define the success of a
                      project?
                    </h5>
                    <p>
                      Any project’s success or failure depends primarily upon four
                      specific parameters (i) Ownership (ii) Approval (iii) Demand
                      and (iv) Delivery.
                    </p>
                    <p>
                      <h6>(i) Ownership </h6>
                      There are various problems associated with the land records
                      system in Pakistan. Apart from being highly outdated (dates
                      back to 1906), the system of changing “Ownership” in land
                      records is extremely complex and manipulative. Therefore, it
                      is often abused by owners and record keepers who take
                      advantage of customers unaware of the Patwari land system. A
                      landowner can manage to sell the same property to more than
                      one buyer, or the physical location of the proposed land may
                      be different from what is given in the land papers. Under
                      these circumstances, the land system becomes unreliable and
                      susceptible to fraudulent property transactions.
                      <h6 className="pt-3"> (ii) Approval </h6>
                      The “Approval” of the project is its existing status regarding
                      necessary approvals, planning permissions and compliance to
                      applicable by-laws and regulations of the concerned regulatory
                      authority.
                      <h6 className="pt-3"> (iii) Demand </h6>A project is feasible
                      for execution when there exist a market “Demand” for its
                      deliverables. The “Demand” of a project can be determined by
                      analyzing social and demographic aspects of the proposed
                      project and area by carrying out SWOT (Strengths, Weaknesses,
                      Opportunities, and Threats) analysis, PEST (Political,
                      Economic, Social and Technological) analysis, financial
                      analysis as well as demand and supply analysis.
                      <h6 className="pt-3"> (iv) Delivery </h6>
                      “Delivery” of any project is dependent on positive cash flows
                      and effective project management.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>

                    <h5 id="WIFS">
                      What is the importance of feasibility studies in the overall
                      success of a project?
                    </h5>
                    <p>
                      The feasibility studies put emphasis upon the demand and
                      delivery of the project by considering its technical,
                      operational, economic, and financial aspects. They provide
                      stakeholders with the specific knowledge and understanding of
                      the proposed project and best available options that are
                      helpful in making important investment decisions. It all
                      happens through careful survey, evaluation and analysis of the
                      market size and key players in site proximity, site potential,
                      customer demand, market supply position, competitors, possible
                      risks and roadblocks.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WKFS">
                      What kind of feasibility study services does PropSure offer?
                    </h5>
                    <p>
                      PropSure conducts the following three types of feasibility
                      studies:
                      <h6 className="pt-3"> (i) Screening Study </h6>
                      The screening study analyses whether or not the proposed
                      project is feasible for execution in the light of provisions
                      of a master plan, peri-urban structure plan and outline
                      development plan. It examines the project’s ability to meet
                      the requirements of the regulatory body and its applicable
                      by-laws.
                      <h6 className="pt-3"> (ii) Pre-Feasibility Study </h6>
                      The pre-feasibility study incorporates all the major
                      components such as available utilities, livability, title plan
                      information, title register and valuation of the property.
                      Pre-feasibility studies help the decision-makers and investors
                      to decide on a detailed market study/feasibility study.
                      <h6 className="pt-3"> (iii) Market Study </h6>
                      In order to determine the project's viability, a comprehensive
                      market study is conducted that includes SWOT analysis, PEST
                      analysis, financial evaluation, demand and supply analysis,
                      provision of alternatives and their comparison, selection of
                      best alternative, the status of trunk infrastructure and
                      utility services (road, water supply, sewerage, drainage,
                      electricity, gas etc.)
                    </p>

                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WFSCP">
                      What are some of the feasibility studies completed by
                      PropSure?
                    </h5>
                    <p>
                      Feasibility Studies completed by PropSure for the following
                      projects:
                      <ol type="i">
                        <li>
                          <a href="https://golffloras.com.pk/" target="_blank">
                            Golf Floras Apartments, Garden City Bahria Town,
                            Islamabad
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Florence Galleria, DHA Phase-2, Islamabad{" "}
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Amazon Hotel, G-11 Markaz Islamabad
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Imarat Residences- Imarat Developers Pvt. Ltd. Islamabad
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Jinnah Square Apartments Lahore (JSM Heights Private
                            Limited)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Islamabad Business District- - Imarat Developers Pvt.
                            Ltd. Islamabad
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Mall of Arabia (Amazon Mall (SMC) Pvt, ltd.)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Margalla View Apartments (Twin City Housing Pvt. Ltd.
                            Islamabad)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Lahore Times Square (Lahore Times Square Pvt. Ltd.)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Adhwal Housing Scheme, Rawalpindi (Imarat Group)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Amanah Apartments Lahore (Amanah Estate Private Limited)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Wellness Club-Imarat Developers Pvt. Ltd. Islamabad
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Apartment project- Services Cooperative Housing Society
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Romanzah Golf Club (DHA Multan)
                          </a>
                        </li>
                        <li>
                          <a href="" target="_blank">
                            Emaar Crescent Bay (HA Emaar)
                          </a>
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SERVICES SECTION ENDS HERE */}
        {/* FOOTER SECTION STSRTS HERE */}

        <ScrollButton />
        <Footer />
        {/* <section className="FrequentlyAskedQuestions-footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                className="img-fluid w-25"
                src={require("../../assets/img/Logo 2.png").default}
                alt="Logo"
              />
              <div className="para">
                <p>
                  PropSure Digital Solutions (Pvt) Ltd 4th Floor, Beverly
                  Centre, Blue Area, Islamabad
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="contact">
                <p>
                  Contact us at{" "}
                  <b style={{ fontWeight: "bold" }}> info@propsure.com.pk</b>{" "}
                  <br />
                  to get in touch with us.
                </p>
                <p>
                  <i className="fa fa-phone" style={{ fontSize: 20 }} />{" "}
                  051-8442244 <br />
                  021-34541403 <br />
                  042-36407379
                </p>
              </div>
              <div className="contact-icons">
                <i
                  className="fa fa-twitter"
                  aria-hidden="true"
                  style={{ paddingRight: "4%" }}
                />
                <i className="fa fa-instagram" style={{ paddingRight: "4%" }} />
                <i className="fa fa-facebook" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </>
  );
};

export default FeasibilityStudyFAQs;
