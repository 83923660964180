import Header from "../../components/header/Header";
import React, {useEffect} from "react";
import Footer from "../../components/footer/Footer";
import RequestListing from "./RequestListing";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'
import {getPageTitle} from "../../Redux/variables";
const RequestHistories = (props) => {
    useEffect(()=>{
        GoogleView('Request History','/request-history')
    },[])
    return (
        <>
            <Helmet>
                <title>Request History {getPageTitle()}</title>
                <meta name="description" content="" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Request History" />
                <meta property="og:description" content="" />
                <meta property="og:url" content={'/request-history'} />
                <meta property="og:site_name" content="PropSure" />
                <meta property="article:publisher" content="" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header state={props} />
            <RequestListing {...props} />
            <Footer />
        </>
    )
}

export default RequestHistories
