import React, {useEffect} from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import {Link} from "react-router-dom"
import { isMobile } from '../utils'
import Lottie from "react-lottie";
import homeanimation from ".././assets/animations/homescreen/homeanimation.json";
import {GoogleView} from "../Helper/GoogleView";
import {Helmet} from 'react-helmet'
import {getPageTitle} from '../Redux/variables'
function PrivacyPolicy(props) {

        const defaultAnimationOptions = {
          // background:
          //   "radial-gradient(48.68% 52.38% at 51.53% 48.33%, #FE6060 0%, #C62525 100%), radial-gradient(43.6% 45.97% at 50.07% 47.98%, #FD8181 0%, #ED4949 38.84%, #E32C2C 86.81%)",
          loop: true,
          autoplay: true,
          animationData: homeanimation ,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        };
    useEffect(()=>{
        GoogleView('Privacy Policy','/privacy-policy')
    },[])

        return (
          <>
              <Helmet>
     <title>Privacy Policy {getPageTitle()}</title>
<meta name="description" content="" />
{/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Home | PropSure" />
<meta property="og:description" content="" />
<meta property="og:url" content="https://www.propsure.com.pk/" />
<meta property="og:site_name" content="PropSure" />
<meta property="article:publisher" content="" />
<meta property="og:image" content="" />
<meta name="twitter:card" content="summary_large_image" />
    </Helmet>
          <Header state={props}/>
            <section>
              <div className="container-fluid ist-section grey-color-1">
              <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 real-estate"
              style={{ padding: 0 }}
            >
              <div className="realestate-ist" style={{ paddingBottom: 35 }}>
                <h1
                  className={`main-head ${isMobile() ? "fs-1" : "fs-1"}`}
                  style={{
                    marginTop: isMobile() ? "112px" : "",
                    padding: isMobile() ? 0 : "",
                  }}
                >
                  Privacy <span>Policy</span>
                </h1>
                
                <p className={`main-para ${isMobile() ? "px-2" : "pe-5 mt-4"}`}>
                  <h4 className="fw-bold">Reservation of Rights</h4>
                  We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound by these terms and conditions.
                </p>
                
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
              style={{ padding: 0 }}
            >
              <div
                style={{
                  margin: "auto",
                  background:
                    "linear-gradient(to right,#96286D,#C33352,#EF3E36) ",
                    // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                }}
              >
                <Lottie options={defaultAnimationOptions} />
              </div>
       
            </div>
          </div>
              </div>
            </section>

            <section className='container-fluid ist-section grey-color-2'>
                <div className='container py-3'>
                {/* <h4 className="fw-bold">Your Privacy</h4> */}
                {/* <p>Please read <Link to="/privacy-policies">Privacy Policy</Link></p> */}
                

                <h4 className="fw-bold">Removal of links from our Website</h4>
                <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us . We will consider your requests to remove the said links but we are not obligated to do so or to respond to you directly.</p>
                <h4 className="fw-bold">Disclaimer</h4>
                <p>
                  As permitted under the applicable law, we exclude all representations, warranties and conditions relating to our Website and the use of this Website. Nothing in this disclaimer will:
                  <ul>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                  </ul>
                </p>
                <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including the liabilities arising in contract, in tort and for breach of statutory duty.</p>
                <p>Subject to the applicable laws, the Company shall not be liable for any loss or damages, whether direct or indirect, resulting from and/or arising out of reliance placed on anything contained in this Website.</p>
                <p>We do not ensure that the information on our Website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the Website is kept up to date.</p>
              </div>
            </section>
            {/* REAL ESTATE SECTION ENDS HERE */}
          <Footer />
          </>
        );

}

export default PrivacyPolicy
