import React, {useEffect} from "react";
import Header from "../../components/header/Header";
import "./styles.css";
import {digitalPakistan, getPageTitle} from '../../Redux/variables'


import Lottie from "react-lottie";
import searchAnimation from "../../assets/animations/FAQs/animations/animation.json";
import online from "../../assets/animations/Services/online.json";
import property from "../../assets/animations/Services/Property.json";
import feasibility from "../../assets/animations/Services/feasibility.json";
import forensic from "../../assets/animations/Services/forensic.json";
import technical from "../../assets/animations/Services/technical.json";
import data from "../../assets/animations/Services/data.json";
import thankYou from "../../assets/animations/Thankyou/Thankyouaniamtion.json";
import ChatBox from "../../components/chatBox/ChatBox";
import { useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'

const ForensicAuditFAQs = (props) => {
  const history = useHistory();
  useEffect(()=>{
    GoogleView('Forensic Audit FAQs',`/ForensicAuditFAQs`)
  },[])
  const search = {
    loop: true,
    autoplay: true,
    animationData: searchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: online,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions2 = {
    loop: true,
    autoplay: true,
    animationData: thankYou,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions3 = {
    loop: true,
    autoplay: true,
    animationData: property,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions4 = {
    loop: true,
    autoplay: true,
    animationData: feasibility,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions5 = {
    loop: true,
    autoplay: true,
    animationData: forensic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions6 = {
    loop: true,
    autoplay: true,
    animationData: technical,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationOptions7 = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
      <>
        <Helmet>  <title>Forensic Study {getPageTitle()}</title>
          <meta name="description" content="" />
          {/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home | PropSure" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://www.propsure.com.pk/" />
          <meta property="og:site_name" content="PropSure" />
          <meta property="article:publisher" content="" />
          <meta property="og:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header state={props} />
        <ChatBox />
        {/* FREQUENTLY ASKED QUESTIONS SECTION STSRTS HERE */}
        <section>
          <div className="container-fluid ist-section">
            <div className="grey-color-1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 real-estate">
                  <h1 className="main-head">
                    Frequently <br /> Asked
                    <span>
                  {" "}
                      <br /> Questions
                </span>
                  </h1>
                </div>
                <div
                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
                    style={{ padding: "0%" }}
                >
                  <div
                      style={{
                        margin: "auto",
                        background:
                            "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                            // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                      }}
                  >
                    <Lottie options={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FREQUENTLY ASKED QUESTIONS SECTION ENDS HERE */}
        {/* SERVICES SECTION STSRTS HERE */}
        <section style={{padding:'0px'}} className="second-section FrequentlyAskedQuestions">
          <div className="grey-color-2">
            <div className="container services">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-xs-12 icon-box">
                  <div className="row">
                    {/* <div className="search-area text-center">
                  <input
                    type="search"
                    autoComplete="off"
                    spellCheck="false"
                    role="combobox"
                    placeholder="Search"
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div> */}
                    <div className="catagories-heading text-center">
                      <h4>Categories</h4>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/OnlinePropertyVerificationFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/opvs.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>{digitalPakistan()}</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/PropertyInspectionFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/pins.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Property Inspection Services (PINS)</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/FeasibilityStudyFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/feasibility.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Feasibility Studies</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one">
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/w/forensic-audit.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Forensic Audit</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/TechnicalAdvisoryFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/technical.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Technical Advisory</h6>
                        </div>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          history.push("/DataServiceFAQs");
                        }}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 icon-box-one"
                    >
                      <div className="group-one text-center">
                        <img className={'img-box-mws img-fluid'} src={require("../../assets/img/services/data-as-a-service.svg").default} alt=""/>
                      </div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <h6>Data As A Service</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-xs-12">
                  <div className="main-two">
                    <h1>
                      Forensic <span>Audit</span>
                    </h1>
                    <h5 id="WNFAP">Why forensic audit?</h5>
                    <p>
                      A forensic audit examines and evaluates a firm's or
                      individual's financial records to clarify their position and
                      derive evidence that is presentable in the court of law or a
                      legal proceeding. A forensic audit protects law-abiding
                      individuals and developers from harassment and blackmailing by
                      various investigation and regulatory authorities.
                    </p>
                    <p>
                      In May 2017, the Supreme Court of Pakistan passed an order for
                      the Forensic Audit of commercial and housing ventures to
                      verify the financial matters along with town planning works,
                      development works and allotment related concerns. It placed
                      many developers and builders in trouble who were not
                      maintaining proper accounting records. Therefore, it is of
                      paramount importance for individuals and companies operating
                      in the real estate sector of Pakistan to conduct periodic
                      forensic audits to avoid any sort of litigation.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WTFASPO">
                      Why should you choose PropSure to conduct a Forensic Audit?
                    </h5>
                    <p>
                      PropSure through its team of experts has the required
                      capability to conduct a forensic audit and render satisfactory
                      service to its valued customers to protect them from any
                      future trouble and guide them to maintain their accounts in
                      compliance with the required legal and accounting standards.
                      Forensic audit by PropSure also assists its clients in
                      reducing mismanagement and improving the project's overall
                      performance.
                    </p>
                    <div>
                      <hr className="main-hr" />
                    </div>
                    <h5 id="WPCFA">
                      Which forensic audits have been completed by the Company?
                    </h5>
                    <p>
                      PropSure Digital Solutions has successfully completed the task
                      of forensic audit for the following projects:
                      <ol type="i">
                        <li>Amazon Outlet Mall </li>
                        <li>Imarat Builders Mall </li>
                        <li>Mall of Arabia</li>
                        <li>Amazon Hotel G-11</li>
                        <li>Florence Galleria</li>
                        <li>Golf Floras</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SERVICES SECTION ENDS HERE */}
        {/* FOOTER SECTION STSRTS HERE */}

        <ScrollButton />
        <Footer />
        {/* <section className="FrequentlyAskedQuestions-footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                className="img-fluid w-25"
                src={require("../../assets/img/Logo 2.png").default}
                alt="Logo"
              />
              <div className="para">
                <p>
                  PropSure Digital Solutions (Pvt) Ltd 4th Floor, Beverly
                  Centre, Blue Area, Islamabad
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="contact">
                <p>
                  Contact us at{" "}
                  <b style={{ fontWeight: "bold" }}> info@propsure.com.pk</b>{" "}
                  <br />
                  to get in touch with us.
                </p>
                <p>
                  <i className="fa fa-phone" style={{ fontSize: 20 }} />{" "}
                  051-8442244 <br />
                  021-34541403 <br />
                  042-36407379
                </p>
              </div>
              <div className="contact-icons">
                <i
                  className="fa fa-twitter"
                  aria-hidden="true"
                  style={{ paddingRight: "4%" }}
                />
                <i className="fa fa-instagram" style={{ paddingRight: "4%" }} />
                <i className="fa fa-facebook" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </>
  );
};

export default ForensicAuditFAQs;
