import axios from "axios";
import CryptoJS from 'crypto-js'
import swal from "sweetalert";
//export const API_URL='http://localhost:8000/api/'

console.log(window.location,'window.location.host')
const domainUrl  = window.location.host;
let API_URLS = '';
switch (domainUrl){
    case 'propsure.com.pk':
        console.log(domainUrl,'propsure.com.pk')
        API_URLS = 'https://crm.propsure.co/api/';
        break;
    case 'website.propsure.rocks':
        console.log(domainUrl,'website.propsure.rocks')
        API_URLS = 'https://crm.propsure.rocks/api/';
        break;
    case 'localhost:3000':
        console.log(domainUrl,'localhost')
        API_URLS = 'https://crm.propsure.rocks/api/';
        break;
    default:
        API_URLS = 'https://crm.propsure.co/api/';
        break;
}

export const API_URL=API_URLS
export const TAG_MANAGER_ID='G-GHJV0RH3MY'
export const GOOGLE_CAPTCHA_SITE_KEY = '6LdwB6weAAAAAAgmJMJnJhh0eTm3QNG-u2RkNVde'
export const GOOGLE_AUTH_CLIENT_ID='677233639617-e2031kquitkl88kelui5c3oas21cebl8.apps.googleusercontent.com'
//export const API_URL='https://crm.propsure.rocks/api/'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'
export const SET_LOADER = 'SET_LOADER'
const encryptionKey = 'propsure@11234'


export const setToken = (token) => {
    localStorage.setItem('token',encrypt(token))
}
export const setLocalUser = (user) => {
    user  = JSON.stringify(user)
    localStorage.setItem('user', encrypt(user))
}
export const getUserToken = () => {
    let token = localStorage.getItem('token')
    return ( token !== undefined && token !=='' && token !== null )?decrypt(token):''
}
export const getLoginUser = () => {
    let user = localStorage.getItem('user')
    try{
        return ( user !== undefined && user !=='' && user !== null )?JSON.parse(decrypt(user)): {}
    }catch (e) {
        removeTokenUser()
        return {}
    }
}
export const setDefaultHeaderApi = () => {
    const accessToken = getUserToken();
    if (accessToken){
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
}
export const  removeTokenUser = () => {
    console.log('remove hit')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
}
export const UnauthorizedAccess = (error) =>{
    if (error.response.status ===401){
        removeTokenUser()
        swal({ icon: "error", text: 'Session Expire Please login again.' }).then((value) => {
            window.location.href = '/';
        })
    }
}
export const encrypt = (data) =>{
    return  CryptoJS.AES.encrypt(data, encryptionKey).toString();
}
export const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export const errorMessages = (message) => {
    const keys = Object.keys(message);
    let messages = '<ul class="props-errors">'
    keys.map((row,index) => {
        messages += (message[row][0] !== null && message[row][0] !== '' &&  message[row][0] !== undefined)?`<li><b>${row}</b> : ${message[row][0]}</li>`:''
    })
    messages += '</ul>'
    return messages
}
export const removeSpacesFromString = (str) => {
    return str.replace(/ /g,"_")
}
export const imageDataToBlob = function(imageData){
    let w = imageData.width;
    let h = imageData.height;
    let canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx.putImageData(imageData, 0, 0);        // synchronous

    return new Promise((resolve) => {
        canvas.toBlob(resolve); // implied image/png format
    });
}
export const getPageTitle = ()=>{
    return '| Propsure'
}
export const digitalPakistan = () =>{
    return 'Online Property Verification System (OPVS)';
}
export const locateYourProperty = () =>{
    return 'Verify Your Property';
}
