import React, { useContext, useState } from "react";
import "./styles.css";

import Lottie from "react-lottie";
import homeanimation from "../../assets/animations/homescreen/homeanimation.json";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { isMobile } from "../../utils";
import {locateYourProperty} from "../../Redux/variables";

const RealEstate = () => {
  const {
    currentUser,
    setCurrentUser,
    loggedIn,
    setLoggedIn,
    mapClicked,
    setMapClicked,
  } = useContext(AuthContext);

  const defaultAnimationOptions = {
    // background:
    //   "radial-gradient(48.68% 52.38% at 51.53% 48.33%, #FE6060 0%, #C62525 100%), radial-gradient(43.6% 45.97% at 50.07% 47.98%, #FD8181 0%, #ED4949 38.84%, #E32C2C 86.81%)",
    loop: true,
    autoplay: true,
    animationData: homeanimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const checkUser = () => {
    if (currentUser) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      setMapClicked(true);
    }
  };
  return (
    <>
      {loggedIn && <Redirect to={"/map"} />}
      {/* REAL ESTSTE SECTION STSRTS HERE */}
      <section>
        <div className="container-fluid ist-section grey-color-1">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 real-estate"
              style={{ padding: 0 }}
            >
              <div className="realestate-ist" style={{ paddingBottom: '35px' }}>
                <h1
                  className={`main-head ${isMobile() ? "fs-1" : ""}`}
                  style={{
                    marginTop: isMobile() ? "112px" : "",
                    padding: isMobile() ? 0 : "",
                  }}
                >
                  <span> Propsure</span><br/> Be Sure
                </h1>

                <p className={`main-para ${isMobile() ? "px-2" : "pe-5"}`}>
                  <h3 style={{display: 'none'}}>Property verification services, Save your investment, bylaws report, fesability report and other 4 services, property insurity, digitised pakistan, protecting your investment in real estate, real state investment, Pakistan real estates,</h3>

                  As a pioneer of digital transformation in Pakistan’s real
                  estate sector, we are offering first-ever online property
                  verification and inspection services through the application
                  of disruptive technologies and innovative digital solutions.
                </p>
                <div className="button-portion">
                  <button
                    onClick={() => checkUser()}
                    data-bs-toggle="modal"
                    data-bs-target="#OnlinePropertyVerification"
                    className="property-btn"
                  >{locateYourProperty()}
                  </button>
                  {/* <button className="map-btn" onClick={() => checkUser()}>
                    PropMap
                  </button> */}
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
              style={{ padding: 0 }}
            >
              <div
                style={{
                  margin: "auto",
                  background:
                    "linear-gradient(to right,#96286D,#C33352,#EF3E36)",
                    // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(19,99,223,1) 0%, rgba(71,181,255,1) 100%)",
                }}
              >
                <Lottie options={defaultAnimationOptions} />
              </div>
              {/* Modal */}
              <div id="Chating-box" className="modal">
                <div className="modal-dialog modal-xl">
                  <div
                    className="modal-content"
                    style={{ width: "50%", float: "right" }}
                  >
                    <div className="modal-body media-body">
                      <div className="text-center">
                        <h4>
                          Property <br />
                          <span>Inspection</span>
                        </h4>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-10 offset-lg-1">
                            <div className="media-content text-center">
                              <h1>
                                Thank you for filling out your information!
                              </h1>
                              <p>
                                One of our representatives will be in touch with
                                you soon. <br />
                                Meanwhile, please feel free to download our
                                monthly newsletter to get updated on all our
                                recent activities.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* REAL ESTATE SECTION ENDS HERE */}
    </>
  );
};

export default RealEstate;
