import calenderDubai from "../../assets/events/calenderDubai.png";
import locationDubai from "../../assets/events/locationDubai.png";
import investRight from "../../assets/events/investRight.png";
import in1 from "../../assets/events/in1.png";
import OADD from "../../assets/events/OADD.png";
import analysis from "../../assets/events/analysis.png";
import finance from "../../assets/events/finance.png";
import tools from "../../assets/events/tools.png";
import projects from "../../assets/events/projects.png";
import React from "react";

const LeftSide = (props) => {

    return (
        <div className="event-time col-md-7">
            <h2 className="re-show-head">
                What is <img className="alignnone size-full wp-image-14661"
                                 src={investRight} alt=""
                                 width="188" height="41"
                                />
            </h2>
            <p className="re-show-para">
                Making informed investment decisions in commercial and residential real estate hinges on understanding
                micro and macroeconomic theories and frameworks, and using practical financial analysis tools. Invest
                Right is a one-of-a-kind course that offers a rounded understanding of property and the needed
                technology-oriented services to deal with unique challenges faced by the country’s real estate sector.
                It allows potential investors the opportunity to achieve a greater understanding of complex issues such
                as finance, tax laws and zoning, mortgages, insurance and banking. The course will support our valued
                investors and partners to understand industry dynamics and make informed decisions to grab opportunities
                regarding real estate investments and developments.
            </p>
            <h2 className="re-show-head">What the course offers:</h2>

            <ul className={'mws_items'}>
                <li><img src={in1} alt=""/> Invest Right in Real Estate - An Introduction</li>
                <li><img src={OADD} alt=""/> OADD Framework</li>
                <li><img src={analysis} alt=""/> Property Valuation and Investment Analysis</li>
                <li><img src={finance} alt=""/> Leverage, and Financing the Investment</li>
                <li><img src={tools} alt=""/> How to make the right investment at the right time</li>
                <li><img src={projects} alt=""/> Renowned Speakers</li>
                <li><img src={in1} alt=""/> The Ultimate Invest Right Handbook</li>
            </ul>
            <h2 className="re-show-head">Seminars</h2>
            <div className="d-flex">
            <div className="seminars">
                <div className="mws_events">
                    <h2>
                        <span className="date">17</span><br/>
                        <span className="month">SEP </span><br/>
                        <span className="city">Dubai</span><br/>
                        {/*<span className="city"></span>*/}

                    </h2>
                </div>
            </div>
                <div className="seminars">
                    <div className="mws_events">
                        <h2>
                            <span className="date">O7</span><br/>
                            <span className="month">OCT </span><br/>
                            <span className="city">PC, RWP</span><br/>
                            {/*<span className="city">Rawalpindi</span>*/}


                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftSide;