import React from "react";
import { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { nodeProd } from "../../../../config";
import Axios from "axios";
import { Link } from "react-router-dom";
import { isMobile } from "../../../../utils";
import {useDispatch} from "react-redux";
import ApiAuth from "../../../../API/ApiAuth";
import swal from "sweetalert";
import {toast} from "react-toastify";

function ElasticSearch(props) {
  const dispatch = useDispatch()
  const [items, setItems] = useState([
    {
      id: 0,
      name: "No results found",
    },
  ]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    let itemArr = []
    ApiAuth.searchPlot({param:string}).then((response) =>{
      const {success,data,message} = response
      if (success === true ) {
        let {hits} = data.hits;
        if (hits) {
          hits.map((obj, i) => {
            itemArr.push({
              id: i,
              name: obj._source.address,
              centroid: obj._source.centroid,
              zoom: obj._source.zoom,
            });
          });
          setItems(itemArr);
        } else {
          itemArr.push({
            id: 0,
            name: "No results found",
          });
          setItems(itemArr);
        }
      }

    }).catch((error) => {
      itemArr.push({
        id: 0,
        name: "please wait...",
      });
      setItems(itemArr);
      toast.error(error)
    })

    /*Axios.get(`${nodeProd}search?param=${string}`, {
      headers: {
        authToken: `${props.user.accessToken}`,
      },
    })
      .then(function (response) {
        let data = response.data.hits.hits;

        if (data) {
          data.map((obj, i) => {
            itemArr.push({
              id: i,
              name: obj._source.address,
              centroid: obj._source.centroid,
              zoom: obj._source.zoom,
            });
          });
        } else {
          itemArr.push({
            id: 0,
            name: "No results found",
          });
        }

        setItems(itemArr);
      })
      .catch(function (error) {
        console.log(error);
        itemArr.push({
          id: 0,
          name: "please wait...",
        });
      });*/
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    console.log(item);
    // the item selected
    try {
      let LatLng = {
        lat: parseFloat(item.centroid.split(",")[1]),
        lng: parseFloat(item.centroid.split(",")[0]),
      };

      props.setcentroid(LatLng);

      props.map.setView(LatLng, item.zoom);
    }
    catch (e) {
      toast.error(e.message)
    }

    // GetFeatureInfo(LatLng, props.map, props.setplotData);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return item;
  };
  return (
    <>
      <div style={styles.panelStyle} className="drop-shadow">
        <div>
          <img
              onClick={() => window.location.href=("/")}
            height={isMobile() ? "50px" : "70px"}
            src={
              props.open
                ? require("../../../../assets/img/PropSure-Logo-White-new.png").default
                : require("../../../../assets/img/PropSure-Logo-map-new.png").default
            }
            alt="Property with surety"
          />
        </div>

        <div className="mt-2">
          <ReactSearchAutocomplete
            items={items}
            placeholder={"Plot Street Housing Scheme City"}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            showIcon={false}
            formatResult={formatResult}
            styling={styles.searchStyling}
          />
        </div>
      </div>
    </>
  );
}

const styles = {
  panelStyle: {
    width: isMobile() ? 240 : 270,
    alignItems: "center",
    padding: 10,
    borderRadius: 10,
    paddingTop: 15,
    marginLeft: 7,
    justfyContent: "space-around",
    position: "absolute",
    zIndex: 2000,
    cursor:'pointer',
  },
  searchStyling: {
    height: "44px",
    border: "1px solid #dfe1e5",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    fontSize: "12px",
    fontFamily: "Arial",
    lineColor: "rgb(232, 234, 237)",
    placeholderColor: "grey",
    clearIconMargin: "3px 14px 0 0",
    searchIconMargin: "0 0 0 16px",
  },
};

export default ElasticSearch;
