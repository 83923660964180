import L from "leaflet";
import {API_URL} from "./Redux/variables";
const geoserverbase =
  "https://geoserver.propsure.co/geoserver/propsure_prod/wms";
//const geoserverbase = `${API_URL}plot-details/gis-map`;

const geoserverStore = "propsure_prod:";

const nodeProd = "https://propapp.propsure.co/api/";
const nodeDev = "http://localhost:3031/api/";

const laravelBaseUrl = "http://localhost:8000/api/";

const mapbounds = L.latLngBounds(
  L.latLng(34.70549341022547, 86.22070312500001),
  L.latLng(25.859223554761407, 52.47070312500001)
);

export {
  geoserverbase,
  geoserverStore,
  nodeProd,
  nodeDev,
  mapbounds,
  laravelBaseUrl,
};
