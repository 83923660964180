import React, {useEffect} from "react";
import ChatBox from "../../components/chatBox/ChatBox";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import OurValues from "../../components/ourValues/OurValues";
import PropMap from "../../components/propMapSection/PropMap";
import RealEstate from "../../components/realEstateSection/RealEstate";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import Services from "../../components/servicesSection/Services";
import WhyPropMap from "../../components/whyPropMapSection/WhyPropMap";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from "react-helmet";
import {getPageTitle} from '../../Redux/variables'

const Home = (props) => {

    useEffect(()=>{
        GoogleView('Home','/')
    },[])
    return (
        <>
            <Helmet>
                <title>Home {getPageTitle()}</title>
                <meta name="description" content="" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Home | PropSure" />
                <meta property="og:description" content="" />
                <meta property="og:url" content="https://www.propsure.com.pk/" />
                <meta property="og:site_name" content="PropSure" />
                <meta property="article:publisher" content="" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header state={props} />
            <ChatBox />
            <RealEstate />
            <Services />
            <PropMap />
            <WhyPropMap />
            <OurValues />
            <Footer />
            <ScrollButton />
        </>
    );
};

export default Home;
