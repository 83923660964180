import axios from "axios";
import {API_URL, setDefaultHeaderApi, UnauthorizedAccess} from "../Redux/variables";

class ApiAuth
{
    static login(data)
    {
        return new Promise((resolve,reject)=>{
            data.captcha=true
            axios.post(`${API_URL}login`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error?.response?.data.message)
            })
        })
    }
    static register(data)
    {
        return new Promise((resolve,reject)=>{
            data.captcha=true

            axios.post(`${API_URL}register`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error?.response?.data.message)
            })
        })
    }
    static forgetPassword(data)
    {
        return new Promise((resolve,reject)=>{
            axios.post(`${API_URL}forget-password`,data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error?.response?.data.message)
            })
        })
    }
    static forgetPasswordUpdate(data)
    {
        return new Promise((resolve,reject)=>{
            axios.post(`${API_URL}forget-password-user`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error?.response?.data.message)
            })
        })
    }
    static registerEmailVerification(data)
    {
        return new Promise((resolve,reject)=>{
            axios.post(`${API_URL}email-verification`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error?.response?.data.message)
            })
        })
    }


    static updateProfile(data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            axios.post(`${API_URL}user-profile`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }
    static logout(data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            axios.post(`${API_URL}logout`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }

    static sendPlotRequest(data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            console.log('api data',data)
            axios.post(`${API_URL}add-plot-request`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }

    static sendPinsRequest(slug,data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            console.log('api data',data)
            axios.post(`${API_URL}pins/${slug}`,data).then((response)=>{
            resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }


    static searchPlot(data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            axios.get(`${API_URL}plot-search?param=${data.param}`).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }
    static getPlotRequests(data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            axios.post(`${API_URL}get-plot-requests`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }
    static cancelPlotRequest(requestId,data)
    {
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            axios.post(`${API_URL}plot-request/cancel/${requestId}`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }
    static plotDetails(data)
    {
        const {
            service,version,request,BBOX,INFO_FORMAT,WIDTH,HEIGHT,layers,query_layers,srs,x,y,feature_count
        } = data
        return new Promise((resolve,reject)=>{
            setDefaultHeaderApi()
            const queryString = `?service=${service}&version=${version}&request=${request}&INFO_FORMAT=${INFO_FORMAT}&BBOX=${BBOX}&WIDTH=${WIDTH}&HEIGHT=${HEIGHT}&layers=${layers}&query_layers=${query_layers}&srs=${srs}&x=${x}&y=${y}&feature_count=${feature_count}`
            axios.get(`${API_URL}plot-details/maps${queryString}`).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }
    static loginWithGoogleAccount(data)
    {
        return new Promise((resolve,reject)=>{
            axios.post(`${API_URL}google-login`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                UnauthorizedAccess(error)
                reject(error?.response?.data.message)
            })
        })
    }
    static sendMessage(data)
    {
        return new Promise((resolve,reject)=>{
            axios.post(`${API_URL}send-email`,data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error?.response?.data.message)
            })
        })
    }


}
export default ApiAuth